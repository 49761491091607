import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


import { dateFromMillisecond } from '../config/Configuration';
import Sponser from '../images/sponsered.png';
import Simg from '../images/s-img.png';
import Fb from '../images/fb.png';
import axios from '../api/axios';
import { useSelector, useDispatch } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

function AdFilter(props) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);

  const [value, setValue] = useState(0); // Initial value of the slider

  const handleChange = (newValue) => {
    setValue(newValue);
  };



  const uniqueNames = new Set();
  const result = props.filterArr.filter(({ label }) => {
    if (uniqueNames.has(label)) {
      return false;
    } else {
      uniqueNames.add(label);
      return true;
    }
  });


  return (
    <>
      <h1></h1>
      <div className='row'>
        <div className='col-md-12'>
          <div className='extension-bar dashbaord-extension'>
            <div className='extension-left'>
              <div className='search-input-custom'>
                <MultiSelect
                  closeOnChangedValue={false}
                  options={result}
                  value={props.selected}
                  onChange={props.setSelected}
                  labelledBy="Select"
                />
                {/* <input type='text' placeholder='Search...' /> */}
                {/* <i className='fa-solid fa-magnifying-glass'></i> */}
              </div>
            </div>
            <div className='extension-left center'>
              <label className='amount-spent m-0'>Revenue
                <div className='range-slider-div'>
                  <Slider
                    min={0}
                    max={10}
                    value={props.revanueValue}
                    onChange={props.handleRevanueChange}
                  />
                  <span>${props.revanueValue}</span>
                  {/* <input type="range" min='1' max='100' value='25' ng-model="slide" /> */}
                </div>
              </label>
            </div>
            <div className='extension-left right'>
              {/* <div className='sort-ads'>
                        <a className='sort-ads-a filter-btn' href="#"><img src={Filter} alt='filter' />Filter By</a>
                      </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdFilter