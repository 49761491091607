import React, { useState, useEffect } from 'react';
import Blogo from '../images/black-logo.png';
import Pro from '../images/pro.png';
import D1 from '../images/d1.svg';
import D2 from '../images/d2.svg';
import D3 from '../images/d3.svg';
import D4 from '../images/d4.svg';
import Logout from '../images/logout.svg';
import P1 from '../images/p1.png';
import P2 from '../images/p2.png';
import P3 from '../images/p3.png';
import P4 from '../images/p4.png';
import P1c from '../images/p1c.png';
import P2c from '../images/p2c.png';
import P3c from '../images/p3c.png';
import P4c from '../images/p4c.png';
import ProfileImg from '../images/profile-img.png';
import Camera from '../images/Camera.png';
import Lock from '../images/Lock.svg';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import UserProfileSidebar from '../components/UserProfileSidebar';
import axios from '../api/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { encryptData, app_base_url } from "../config/Configuration";
import copy from 'copy-to-clipboard';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function PlanHistory() {
  const [isActive, setIsActive] = useState(false);



  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [affiliateLink, setAffiliateLink] = useState('');

  const [totalDiscount, setTotalDiscount] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setEmail(storedUser);
    }
  }, []);
  const user_id = localStorage.getItem('user_id');
  const token = JSON.parse(localStorage.getItem('token'));

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('affilate_link'));

    if (user_id) {
      setAffiliateLink(app_base_url + 'signup/?ref=' + encryptData(user_id));
    }

    getPlanHistory();
  }, []);



  const getPlanHistory = () => {
    //  setLoader(true);

    const config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    };

    const bodyParameters = {
      id: user_id
    };
    axios
      //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
      .post("/plan-history", bodyParameters, config)
      .then((res) => {
        console.log('res.result', res.data.total);

        setTotalDiscount(res.data.total);
        setData(res.data.data);
        //   setLoader(false);
      })
      .catch((err) => {
        //   setLoader(false);
      });
  };



 


  return (
    <>
      <DashboarderHeader />
      <div className="container-fluid">
        <div className="row">
          <DashboarderSidebar />
          <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
          <div className="col-md-9 col-lg-10 ml-md-auto px-0 custom-nine-col">
            <main className="container-fluid main-container">
              <div className='profile-div'>
                <UserProfileSidebar />
                <div className='login-inner affilate-link-div'>

                  <Tabs>
                    <TabList>
                      <Tab>Plan History</Tab>
                    </TabList>


                    <TabPanel>
                      <h2>Plan History</h2>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Plan Name</th>
                            <th scope="col">Status </th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length > 0 ? (
                            data.map(item => (


                              <>
                                <tr>
                                  <td>{item.plan.name}</td>
                                  <td>

                                    {(() => {
                                      if (item.active_plan_status == 0) {
                                        return (
                                          <>Pending</>
                                        )
                                      }
                                      if (item.active_plan_status == 1) {
                                        return (
                                          <>Active</>
                                        )
                                      }
                                      if (item.active_plan_status == 2) {
                                        return (
                                          <>Cancel in progress</>
                                        )
                                      }
                                      if (item.active_plan_status == 3) {
                                        return (
                                          <>Cancelled</>
                                        )
                                      }
                                    })()}
                                  </td>
                                  <td> {item.created_at}</td>
                                </tr>

                              </>
                            ))
                          ) : (
                            <p>No data available</p>
                          )}


                        </tbody>
                      </table>
                    </TabPanel>
                  </Tabs>



                </div>
              </div>
            </main>
          </div>
        </div>
      </div>


    </>
  )
}

export default PlanHistory