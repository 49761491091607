import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/pagination';
import { dateFromMillisecond } from '../config/Configuration';
import Sponser from '../images/sponsered.png';
import Simg from '../images/no-image-256.png';
import blackCircle from '../images/blackCircle.jpg';

import Fb from '../images/fb.png';
import axios from '../api/axios';
import { useSelector, useDispatch } from 'react-redux';


import { ToastContainer, toast } from 'react-toastify';
import { fetchAd, myAd } from '../redux/store/actions/adActions';
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';
import { checkImpressionFunc } from '../config/fbAdImpression';

function FacebookAdBox(props) {
    const dispatch = useDispatch();
    const maxLength = 100;
    //console.log('checkImpression', props.checkImpression['views']);

    // console.log('props', props);
    const products = useSelector((state) => state.products);

    const [isExpanded, setIsExpanded] = useState(false);


    const record = JSON.parse(props.item.record);
    // console.log('record', record);
    const publisherPlatform = record ? record.publisherPlatform : '';

    const token = JSON.parse(localStorage.getItem('token'));
    const [impressionData, setImpressionData] = useState([]);

    const [savedStatus, setSavedStatus] = useState(false);
    const [brandImage, setBrandImage] = useState(false);
    const [mediaImage, setMediaImage] = useState(false);

    useEffect(() => {
        getData(record);
        loadDefauldMedia();

    }, []);


    const loadDefauldMedia = () => {
        let tempBrandImg = '';//record.snapshot ? record.snapshot.page_profile_picture_url : '';

        if (props.item.ad_logo) {
            tempBrandImg = process.env.REACT_APP_BACKEND_BASE_URL+'/' + props.item.ad_logo;

        }
        else if (record.snapshot.page_profile_picture_url) {
            tempBrandImg = record.snapshot.page_profile_picture_url;

        }
     
        setBrandImage(tempBrandImg);

        let tempMediaImg = '';

        if (props.item.ad_image) {
            tempMediaImg = process.env.REACT_APP_BACKEND_BASE_URL+'/' + props.item.ad_image;

        }
        else if (props.item.cardResizedImageUrl) {
            tempMediaImg = props.item.cardResizedImageUrl;

        }

        setMediaImage(tempMediaImg);
    };

    async function getData(record) {
        // const data = await checkImpressionFunc(record);
        // console.log(data);
        // setImpressionData(data);
    }
    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const onErrorBrandImage = () => {
        setBrandImage(blackCircle);
    };

    const onErrorMediaImage = () => {
        setMediaImage(Simg);
    };

    const handleCheckSpend = async () => {
        const data = await checkImpressionFunc(record);
        console.log('handleCheckSpend', Object.keys(data).length);
        setImpressionData(data);
    }

    const handleSaveAd = async (id) => {
        dispatch(startLoading());

        // alert(id);
        const config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        const bodyParameters = {
            id: id
        };
        axios
            //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
            .post("/dashboardAds/save", bodyParameters, config)
            .then((response) => {
                if (response.data.status) {
                    toast.success(response.data.message);
                    setTimeout(
                        function () {
                            //window.location.reload();
                            // props.callbackSavedAd();
                            //setSavedStatus(true);
                            dispatch(fetchAd());
                            dispatch(myAd());


                        }
                            .bind(this),
                        3000
                    );

                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((err) => {
                toast.error(err.message || "An error occurred. Please try again.");

            });
    };

    // console.log('props.revanueValue', props.revanueValue);
    // console.log('props.impressionData', impressionData);


    return (

        <>
            {(() => {

                if (props.revanueValue == 0 || (impressionData['day'] && impressionData['day'] < props.revanueValue)) {

                    return (
                        <>
                            <div className='col-md-3'>
                                <div className='download-media'>
                                    <div className='bookmark-title'><span>Library IDs: {props.item.library_id}</span><a className={props.item.myads.length > 0 ? "bookmark-saved" : ""} onClick={(e) => handleSaveAd(props.item.id)} href="#"><i className="fa-solid fa-bookmark"></i></a></div>
                                    <div className='d-media'>
                                        <div className='d-media-left'>
                                            <div className='d-media-active'><i className="fa-solid fa-circle-check"></i>Active</div>
                                            <p>Started running on {dateFromMillisecond(props.item.startDate)} </p>
                                            <div className='d-media-active'>Platform
                                                {publisherPlatform && publisherPlatform.length > 0 ? (
                                                    publisherPlatform.map(socialName => (


                                                        <>

                                                            <i class={'fa-brands fa-' + socialName}></i>

                                                        </>
                                                    ))
                                                ) : ('')}

                                            </div>
                                            <p>This ad has multiple versions   <i className="fa-solid fa-exclamation"></i></p>
                                        </div>

                                        {/* <div className='d-media-right'>
                            <span className='views-price'> {props.checkImpression?props.checkImpression:'No Data'}</span>
                        </div> */}

                                        <div className='d-media-right'>
                                            {Object.keys(impressionData).length == 0 ? <button type='button' onClick={handleCheckSpend} class="check-spend views" >Check Spend</button> : ''}
                                            {Object.keys(impressionData).length > 0 ? <>
                                                <span className='views'>{impressionData['views'] ? impressionData['views'] : 0} Views</span>
                                                <span className='views-price'>€{impressionData['spend'] ? impressionData['spend'] : 0}</span>
                                                <span className='views-time'>{impressionData['day'] ? impressionData['day'] : 0}€/ Days</span>
                                            </> : ''}
                                        </div>
                                        {/* <div className='d-media-right'>
                            <span className='views'>{props.checkImpression['views']} Views</span>
                            <span className='views-price'>€{props.checkImpression['spend']}</span>
                            <span className='views-time'>{props.checkImpression['day']}€/ Day</span>
                        </div> */}
                                    </div>
                                    <a target='_blank' href={record && record.snapshot ? record.snapshot.link_url : ''} className='download-media-addbtn'>{record.snapshot ? record.snapshot.cta_text : ''} Ads Media</a>
                                    <div className='sponsered-div'>
                                        {(() => {

                                            return (<>
                                                <img src={brandImage} onError={() => onErrorBrandImage()} alt='sponser' />
                                            </>)

                                        })()}
                                        <div className='sponser-right'>
                                            <div className='sponser-title'>{record.snapshot ? record.snapshot.page_name : ''}</div>
                                            <p>Sponsored</p>
                                        </div>
                                    </div>
                                    <p>
                                        {(() => {
                                            if (props.item.cardBody) {
                                                return (

                                                    <>
                                                        {isExpanded ? props.item.cardBody : `${props.item.cardBody.substring(0, maxLength)}...`}
                                                        <button className='check-spend more' onClick={toggleReadMore}>
                                                            {isExpanded ? 'Read Less' : 'Read More'}
                                                        </button>
                                                    </>
                                                )
                                            }
                                        })()}
                                    </p>
                                    {/* <ul className='sponser-ul'>
                          <li>✅ 🏆Free Delivery🎉Min. 50% Off⭐COD</li>
                          <li>✅ Premium Shirts & Kurta at Best price!</li>
                          <li>✅ Get Extra Discount at Checkout! 🎉</li>
                        </ul> */}
                                    <img className='s-img' src={mediaImage} alt='s-img' onError={() => onErrorMediaImage()} />
                                </div>
                            </div>
                        </>)
                }
            })()}
        </>
    )
}

export default FacebookAdBox