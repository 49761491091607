import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { dateFromMillisecond } from '../config/Configuration';
import Sponser from '../images/sponsered.png';
import Simg from '../images/s-img.png';
import Fb from '../images/fb.png';
import axios from '../api/axios';

import { ToastContainer, toast } from 'react-toastify';
import Amazon from '../images/amazon.png';
import Following1 from '../images/following1.png';
import Following2 from '../images/following2.png';
import Following3 from '../images/following3.png';
import Following4 from '../images/following4.png';
import Following5 from '../images/following5.png';
import Following6 from '../images/following6.png';
import Myntra from '../images/myntra.png';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FollowerAdBoxCardItem from "./FollowerAdBoxCardItem";

function FollowerAllAdBox(props) {


   // console.log('records', props.records);
  //  console.log('item', props.item);


    const token = JSON.parse(localStorage.getItem('token'));

    const [savedStatus, setSavedStatus] = useState(false);



    


    return (
        <>

            <div className='row'>
            <h3>{props.pageName}</h3>
              
                
                        {props.records.map((ad) => {
                            const record = JSON.parse(ad.record);
                            console.log('record dT  ',record);
                            return (
                                
                                <div className='col-md-3'>
                              
                                <FollowerAdBoxCardItem
                                
                                    ad={ad}
                                    record={record}
                                />
                              </div>
                        )
                        })

                        }
                        {/* 
                        {props.records.length > 0 && (
                            props.records.map(ad => (

                                const record = JSON.parse(ad.record);
                                return(
                                <>
                                    <div className='download-media'>
                                        <div className='bookmark-title'><span>Apr 10, 2024</span></div>
                                        <div className='time-span-div d-media-right'>
                                            <span className='views'>8.20M Views</span>
                                            <span className='views-price'>€20.5-€25</span>
                                        </div>
                                        <img className='follow-img' src={props.item.cardResizedImageUrl?ad.cardResizedImageUrl:Following1} alt='following1' />
                                        <p className='desc-text1'>{ad.cardBody}</p>
                                        <a target='_blank' href={record.snapshot.link_url} className='login-btn1 see-ads'>See Ad</a>
                                    </div>

                                </>)
                            )) */}

                    

              
            </div>


        </>
    )
}

export default FollowerAllAdBox