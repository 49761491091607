import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Commission from '../images/commission.png';
import Footer from "../components/Footer";
import Header from "../components/Header";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SubscriptionPlan = ({ user, user_name }) => {
  const userToken = JSON.parse(localStorage.getItem('user'));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const [user_email, setUserLoggedin] = useState(null);
  const [users_name, setUserLoggedName] = useState(null);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUserLoggedin(storedUser);
    }
  }, []);

/*   useEffect(() => {
    const storedUserName = JSON.parse(localStorage.getItem('user_name'));
    if (storedUserName) {
      setUserLoggedName(storedUserName);
    }
  }, []); */

  const navigate = useNavigate();

  const customRedirect = async () => {
    if (!userToken) {
      navigate("/login", {
        state: {
          navigate: "/subscribe"
        }
      });
      return
    }
  }


  const handleToken = async (token, planId, price, user, name) => {
    if (!userToken) {
      navigate("/login", {
        state: {
          navigate: "/subscribe"
        }
      });
      return
    }
    setLoading(true);

    // Load Stripe.js
    const stripe = await loadStripe('pk_test_51MWILlSFcCe675zQJnHDHtQK1W3lvhnrGY5DGvgDyjHBBKAnTaFCZvJRLw9TfSauISk1Jf7mUX7Ysxq3jN5UBxP100GbspQhE9');

    // Create a new Checkout Session
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        planId: planId,
        email: user,
        name: user_name
      }),
    });

    const session = await response.json();

    // Redirect to Checkout
    const result = await stripe.redirectToCheckout({
      sessionId: session.id
    });

    if (result.error) {
      console.error(result.error.message);
      setLoading(false);
      // Show error message to the user
      toast.error("Fail to purchase subscription");
    } else {
      // If payment is successful, redirect to dashboard with success message
      toast.success('Payment successful!');
      navigate("/dashboard");
    }

  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/pricing`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(responseData => {
        if (responseData.status) {
          setData(responseData.data);
        } else {
          throw new Error('Error fetching data');
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className='main-nav'>
        <Header />
        <section className='banner-section pricing-banner'>
          <span className='circle1 small-circle'></span>
          <Container>
            <Row className='justify-content-center'>
              <Col md='8'>
                <h1 className='banner-main-title'>Pricing</h1>
                <p className='banner-p'>
                 
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className='our-top-features pt-5 pb-5'>
        <div className='container'>
          <Row className='justify-content-center mt-2'>
            <Col md='8 justify-content-center'>
              <div className='main-title mb-5'>Plan that Match your Needs</div>
            </Col>
          </Row>
          <Row style={{cursor: "pointer"}} onClick={customRedirect} className='mb-5 justify-content-center d-none hide'>
            <Col md='10'>
              <div className='lifetime-plan'>
                <img className='commission' src={Commission} alt='commission' />
                <div className='row align-items-center'>
                  <div className='col-md-6'>
                    <div className='lifetime-price'>€497</div>
                  </div>
                  <div className='col-md-6'>
                    <ul className='feature-ul'>
                      <li>
                        <i className="fas fa-circle-check"></i>
                        <span>Lifetime access to all SpyderAds features.</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-circle-check"></i>
                        <span>Access to 2 weeks' free coaching with Owen Ecom and his team.</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-circle-check"></i>
                        <span>Lifetime access to Owen Ecom's paid training course.</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-circle-check"></i>
                        <span>Access to new features before anyone else.</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-circle-check"></i>
                        <span>40% lifetime affiliate commission (instead of 30%)</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-circle-check"></i>
                        <span>Access to a community group with private lives, winning products and much more</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className='row mb-5 '>
            <div className='col-md-8 offset-md-2 col-sm-12'>
              <div className='row'>
                {data.length > 0 ? (
                  data.map(item => (
                    <div className='col-md-6' key={item.id}>
                      <div className={`match-div ${item.interval === 'year' ? 'active' : ''}`}>
                        <span className='monthly-span'>{item.name}</span>
                        <div className='price-title'>
                          {item.interval === 'year' ? (
                            <>
                              €{item.price} <span className='monthly-price'>${(item.price / 12).toFixed(2)}/Monthly</span>
                            </>
                          ) : (
                            <>€{item.price}</>
                          )}
                        </div>
                        <hr />
                        <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                        <a  className='buy-now-btn' href="/subscribe">Let's Start</a>
                       {/*  <a
                          data-price={item.price}
                          data-user={user_email}
                          data-interval={item.interval}
                          data-name={item.name}
                          data-id={item.stripe_price_id}
                          className='buy-now-btn'
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleToken(
                              e.target.dataset.token,
                              e.target.dataset.id,
                              e.target.dataset.price,
                              e.target.dataset.user,
                              e.target.dataset.name
                            );
                          }}
                        >
                          Buy Now
                        </a> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default SubscriptionPlan;