import { MY_AD } from '../actions/actionsTypes';

const initialState = {
    ads: [],
    filter: '',
};

export default function(state = initialState, action)  {
    console.log('action.MYSAD.payload',action.payload);

    switch (action.type) {
        case MY_AD:
            console.log('action.typ=',action.type)

            return {...state,ads:action.payload}
      
        default:
            return state;
    }
}

