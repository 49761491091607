import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../images/logo.svg';
import Email from '../images/email.svg';
import Message from '../images/message.png';
import ContactBg from '../images/contact-bg.png';
import User from '../images/Profile.svg';
import Footer from "../components/Header";
import Header from "../components/Header";
function ContactUs() {
  return (
    <>
    <div className='main-nav'>
    <Header />

    <section className='banner-section pricing-banner'>
      <span className='circle1 small-circle'></span>
      <Container>
        <Row className='justify-content-center'>
            <Col md='8'>
                <h1 className='banner-main-title'>Contact Us</h1>
                <p className='banner-p'>It is a long established fact that a reader will be distracted by the readable 
                  content of a page when looking at its layout. </p>
            </Col>
        </Row>
      </Container>
    </section>
    </div>

    <section className='our-top-features pt-5 pb-5'>
       <div className='container'>
          <div className='row justify-content-center'>
            <Col md='8'>
              <div className='features-right'>
                  <div className='feature-upper justify-content-center mb-3'>
                    <span>Get In Touch</span>
                  </div>
                  <p>Do you Have Any query? Just Write us a Message! </p>
                  <a className='support-btn' href="#"><img src={Email} alt='email' />Support@lorum.com</a>
              </div>
            </Col>
          </div>
          <div className='row'>
            <div className='col-md-10 offset-md-1 col-sm-12'>
              <div className='contact-us-inner'>
                 <div className='contact-left'>
                    <img src={Logo} alt="Logo" />
                    <span className='circle1 small-circle'></span>
                    <img className='contact-bg' src={ContactBg} alt='contact-bg' />
                 </div>
                 <div className='contact-right login-inner'>
                  <div className='send-message'>Send a Message</div>
                  <form>
                    <div className='login-input'>
                      <input className='custom-input' type="text" placeholder='Name' />
                      <img className='input-img' src={User} alt='user' />
                    </div>
                    <div className='login-input'>
                      <input className='custom-input' type="text" placeholder='Email' />
                      <img className='input-img' src={Email} alt='email' />
                    </div>
                    <div className='login-input'>
                      <textarea className='custom-input' placeholder='Message'></textarea>
                      <img className='input-img' src={Message} alt='message' />
                    </div>
                    <button className='login-btn1' type='submit'>Send Message</button>
                  </form>
                 </div>
              </div>
            </div>
          </div>
       </div>
    </section>
   
    <footer className='custom-footer'> 
       <div className='container'>
         <div className='row'>
           <div className='col-md-12'>
              <div className='footer-upper'>
                <a href="#" className='footer-logo'><img src={Logo} alt='logo' /></a>
                <Nav
                  className="me-auto my-2 my-lg-0 nav-inner"
                  style={{ maxHeight: '100px' }}
                  navbarScroll
                >
                  <Nav.Link href="#">Pricing</Nav.Link>
                  <Nav.Link href="#">About Us</Nav.Link>
                  <Nav.Link href="#">Learn</Nav.Link>
                  <Nav.Link href="#">Contact Us</Nav.Link>
                </Nav>
              </div>
              <hr />
              <div className='footer-bottom'>
              © 2024 Spyderads All Rights Reserved.
              <p className='company-text'>Designed by <a href="https://itechnolabs.ca/">Itechnolabs.ca</a></p>
              </div>
           </div>
         </div>
       </div>
    </footer>
    

    </>
  )
}

export default ContactUs