import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Blogo from '../images/black-logo.png';
import Pro from '../images/pro.png';
import D1 from '../images/d1.svg';
import D2 from '../images/d2.svg';
import D3 from '../images/d3.svg';
import D4 from '../images/d4.svg';
import Logout from '../images/logout.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal, Button } from 'react-bootstrap';

import Commission from '../images/commission.png';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import axios from '../api/axios';

import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptDataString, decryptData, app_base_url } from "../config/Configuration";
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';

function Subscribe() {

  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [lifeTimeData, setLifeTimeData] = useState([]);
  const [error, setError] = useState(null);

  const [user_email, setUserLoggedin] = useState(null);
  const [users_name, setUserLoggedName] = useState(null);


  const [datasetId, setDatasetId] = useState(null);
  const [datasetToken, setDatasetToken] = useState(null);
  const [datasetPrice, setDatasetPrice] = useState(null);
  const [datasetUser, setDatasetUser] = useState(null);
  const [datasetName, setDatasetName] = useState(null);
  const [datasetInterval, setDatasetInterval] = useState(null);



  const [affilateModelStatus, setAffilateModelStatus] = useState(false);

  const [affilateText, setAffilateText] = useState(null);

  const [subscribeStatus, setSubscribeStatus] = useState(false);
  const [subscribeData, setSubscribeData] = useState([]);
  const [allowPromoCode, setAllowPromoCode] = useState(false);
  
  const token = JSON.parse(localStorage.getItem('token'));
  const user_id = localStorage.getItem('user_id');


  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUserLoggedin(storedUser);
    }
  }, []);

  useEffect(() => {
    const storedUserName = JSON.parse(localStorage.getItem('user_name'));
    if (storedUserName) {
      setUserLoggedName(storedUserName);
    }
  }, []);

  const handleBuynowButton = (token, planId, price, user, name, interval, status) => {
    setDatasetToken(token);
    setDatasetId(planId);
    setDatasetPrice(price);
    setDatasetUser(user);
    setDatasetName(name);
    setDatasetInterval(interval);
    if (status == 'create') {
      setAffilateModelStatus(true);

    }
    else {
      handleToken(token, planId, price, user, name, interval);
    }
  }
  const hideAffilateModel = () => {
    setAffilateModelStatus(false);
  };


  const handleAffilateLink = (event) => {
    setAffilateText(event.target.value);
  };

  const handleAffilateSkip = async () => {
    //toast.success('Applied successfully');
    setAffilateModelStatus(false);
    handleToken(datasetToken, datasetId, datasetPrice, datasetUser, datasetName, datasetInterval);
  };

  const handleAffilateApply = async () => {
    dispatch(startLoading());
    // Step 2: Split the URL to get the query string
    const queryString = affilateText.split('?')[1]; // Get the part after ?

    // Step 3: Parse the query string into individual parameters
    const queryParams = queryString ? queryString.split('&') : [];

    // Step 4: Find the specific parameter and convert its value to an array
    let idsArray = [];
    queryParams.forEach(param => {
      const [key, value] = param.split('=');
      if (key === 'ref') {
        idsArray = value.split(','); // Split the value by commas
      }
    });

    // Log the result
    console.log(idsArray); // Output: ["1", "2", "3", "4", "5"]
    // alert(idsArray[0]);
    console.log(decryptDataString(idsArray[0]));



    try {

      const config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      };

      const bodyParameters = {
        code: idsArray[0],
        id: decryptDataString(idsArray[0]),
        plan_id: datasetId
      };
      const response = await axios.post('/affiliate/save', bodyParameters, config);

      console.log('response', response);
      if (response.data.status) {
        /*   localStorage.setItem('affilate_link', JSON.stringify(response.data.data.url));
          setAffiliateLink(response.data.data.url);
          toast.success(response.data.message); */
        if (response.data.status == true) {
          toast.success('Applied successfully');
          setAffilateModelStatus(false);
          handleToken(datasetToken, datasetId, datasetPrice, datasetUser, datasetName, datasetInterval);
        }
        else {
          toast.success(response.data.message);
          dispatch(stopLoading());

        }

      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      toast.error(e.message || "An error occurred. Please try again.");
    }

  };



  const navigate = useNavigate();
  const handleToken = async (token, planId, price, user, name, interval) => {

    dispatch(startLoading());
    // Load Stripe.js
    const stripe = await loadStripe(process.env.REACT_APP_STRIP_PUBLISH_KEY);

    // Create a new Checkout Session
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        planId: planId,
        email: user_email,
        name: users_name,
        interval: interval,
      }),
    });

    const session = await response.json();

    // Redirect to Checkout
    //alert(session.type);
    if(session.status==true)
    {
    if (session.type == 'created') {
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (result.error) {
        console.error(result.error.message);
        dispatch(stopLoading());
        // Show error message to the user
        alert("Fail to purchase subscription");
      } else {
        // If payment is successful, redirect to dashboard with success message
        alert('Payment successful!');
        navigate("/dashboard");
      }
    }
    else {
      toast.success('Plan updated');
      setTimeout(() => {
        window.location.reload();

      }, 2000);
    }
  }
  else{
    toast.error(session.message);
    dispatch(stopLoading());

  }



  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/pricing`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(responseData => {
        if (responseData.status) {
          setData(responseData.data);
          setLifeTimeData(responseData.lifTimePlan);
        } else {
          throw new Error('Error fetching data');
        }
        dispatch(stopLoading());
      })
      .catch(error => {
        setError(error);
        dispatch(stopLoading());
      });
  }, []);

  useEffect(() => {

    getSubscribeDetail();
  }, []);

  const getSubscribeDetail = async () => {
    //  setLoader(true);
    dispatch(startLoading());
    const config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    };

    const bodyParameters = {
      id: user_id
    };
    await axios
      //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
      .post("/pricing/subscribeDetail", bodyParameters, config)
      .then((res) => {
        console.log('res.result', res.data.total);

        setSubscribeStatus(res.data.subscribe_status);
        setAllowPromoCode(res.data.allowPromoCode)
        setSubscribeData(res.data.data);
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(stopLoading());
      });
  };


  console.log('subscribeData', subscribeData);

  return (
    <>
      <DashboarderHeader />
      <div className="container-fluid">
        <div className="row">
          <DashboarderSidebar />
          <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
          <div className="col-md-9 col-lg-10 ml-md-auto px-0 custom-nine-col">
            <main className="container-fluid main-container">
              <section className='plan-divvv pt-5 pb-5'>
                <div className='container'>
                  <Row className='justify-content-center mt-2'>
                    <Col md='8 justify-content-center'>
                      <div className='main-title mb-5'>Plan that Match your Needs</div>
                    </Col>
                  </Row>
                  {(() => {
                    if (lifeTimeData && subscribeStatus == false) {
                      return (
                        <Row className='mb-5 justify-content-center lifetime-dashboard-plan'>
                          <Col md='10'>
                            <div className='lifetime-plan'>
                              <img className='commission' src={Commission} alt='commission' />
                              <div className='row align-items-center'>
                                <div className='col-md-5'>
                                  <div className='lifetime-price'>€{lifeTimeData.price}</div>
                                  <br/>
                                  
                                  <a className='buy-now-btn'
                                    data-price={lifeTimeData.price}
                                    data-user={user_email}
                                    data-interval={lifeTimeData.interval}
                                    data-name={lifeTimeData.name}
                                    data-id={lifeTimeData.stripe_price_id}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleToken(
                                        e.target.dataset.token,
                                        e.target.dataset.id,
                                        e.target.dataset.price,
                                        e.target.dataset.user,
                                        e.target.dataset.name,
                                        e.target.dataset.interval,
                                        'create'
                                      );
                                    }}
                                    href="#"

                                  >Buy Now </a>
                                </div>
                                <div className='col-md-7'>
                                  <div dangerouslySetInnerHTML={{ __html: lifeTimeData.desc }} />
                                  {/* <ul className='feature-ul'>
                                    <li>
                                      <i className="fas fa-circle-check"></i>
                                      <span>It is a long established fact that a reader.</span>
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-circle-check"></i>
                                      <span>It is a long established fact that.</span>
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-circle-check"></i>
                                      <span>It is a long established fact that a reader.</span>
                                    </li>
                                  </ul> */}
                                </div>
                                
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )
                    }
                  })()}

                  {(() => {
                    if (lifeTimeData == null) {
                      return (
                        <div className='row mb-5'>
                          <div className='col-md-8 offset-md-2 col-sm-12'>
                            <div className='row'>
                              {
                                data &&
                                data.length > 0 &&
                                data.map(
                                  (item, i) => {
                                    return (<>

                                      <div className='col-md-6' key={item.id}>
                                        <div className={`match-div ${item.interval === 'year' ? 'active' : ''}`}>
                                          <span className='monthly-span'>{item.name}</span>
                                          <div className='price-title'>
                                            {item.interval === 'year' ? (
                                              <>
                                                <span className='monthly-price'> €{item.price}</span> ${(item.price / 12).toFixed(2)}/Monthly
                                              </>
                                            ) : (
                                              <>€{item.price}</>
                                            )}
                                          </div>
                                           
                                          
                                          <hr />
                                          <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                                          {/* <a
                                  data-price={item.price}
                                  data-user={user_email}
                                  data-interval={item.interval}
                                  data-name={item.name}
                                  data-id={item.stripe_price_id}
                                  className='buy-now-btn'
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleToken(
                                      e.target.dataset.token,
                                      e.target.dataset.id,
                                      e.target.dataset.price,
                                      e.target.dataset.user,
                                      e.target.dataset.name
                                    );
                                  }}
                                >
                                  Buy Now
                                </a> */}
                                          {(() => {

                                            if (subscribeStatus == false) {
                                              return (
                                                <div><a className='buy-now-btn'
                                                  data-price={item.price}
                                                  data-user={user_email}
                                                  data-interval={item.interval}
                                                  data-name={item.name}
                                                  data-id={item.stripe_price_id}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleToken(
                                                      e.target.dataset.token,
                                                      e.target.dataset.id,
                                                      e.target.dataset.price,
                                                      e.target.dataset.user,
                                                      e.target.dataset.name,
                                                      e.target.dataset.interval,
                                                      'create'
                                                    );
                                                  }}
                                                  href="#"

                                                >Buy Now </a>
                                                {allowPromoCode==true && item.coupon_code_status==1 && item.coupon_code != null?<>
                                                  <p>Congrats, you will get <strong> {Math.trunc(item.affiliate_commission)} %  </strong>  off on purchase </p>
                                                
                                                </>:<></>}
                                                
                                                </div>


                                              )
                                            } else if (subscribeData && Object.keys(subscribeData).length > 0 && subscribeData.plan_id == item.id && subscribeData.plan.stripe_price_id == item.stripe_price_id) {

                                              return (
                                                <div><a className='buy-now-btn'
                                                  data-price={item.price}
                                                  data-user={user_email}
                                                  data-interval={item.interval}
                                                  data-name={item.name}
                                                  data-id={item.stripe_price_id}
                                                  onClick={(e) => {
                                                    e.preventDefault();

                                                  }}
                                                  href="#"

                                                >Current Plan </a></div>
                                              )
                                            } else {
                                              return (
                                                <div><a className='buy-now-btn'
                                                  data-price={item.price}
                                                  data-user={user_email}
                                                  data-interval={item.interval}
                                                  data-name={item.name}
                                                  data-id={item.stripe_price_id}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleToken(
                                                      e.target.dataset.token,
                                                      e.target.dataset.id,
                                                      e.target.dataset.price,
                                                      e.target.dataset.user,
                                                      e.target.dataset.name,
                                                      e.target.dataset.interval,
                                                      'update'
                                                    );
                                                  }}
                                                  href="#"

                                                >Update Plan
                                                </a></div>
                                              )
                                            }
                                          })()}



                                        </div>
                                      </div>
                                    </>)
                                  })

                              }

                            </div>
                          </div>
                        </div>
                      )
                    }
                  })()}
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>


      <Modal show={affilateModelStatus} onHide={hideAffilateModel}>
        {/*  <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className='affilate-link'>Affiliate Link</div>
          <p>It is a long established fact that a reader will be distracted by the readable content.</p>
          <h3 className='refrral-title'>Share this referral link to your friends</h3>
          <div className='link'> <input value={affilateText} onChange={handleAffilateLink} className='custom-input affilate-link-input' id="affilate-link-input" type="text" placeholder='www.sample.info/?insect=fireman&porter=attraction#cave' />
          </div>
          <div className='ok-skip-div'>
            <button
              className='login-btn1' onClick={handleAffilateApply} href="#">Ok</button>
            <a className='login-btn1 skip-btn' onClick={handleAffilateSkip} href="#">Skip</a>
          </div>

        </Modal.Body>
        {/*  <Modal.Footer>
          <Button variant="secondary" onClick={hideAffilateModel}>
            Close
          </Button>
          <Button variant="primary" onClick={hideAffilateModel}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

      <div className="modal custom-modal show" id="affilate" tabindex="-1" role="dialog" aria-labelledby="affilateTitle" aria-hidden="false">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <div className='affilate-link'>Affiliate Link</div>
              <p>It is a long established fact that a reader will be distracted by the readable content.</p>
              <h3 className='refrral-title'>Share this referral link to your friends</h3>
              <div className='link'>www.sample.info/?insect=fireman&porter=attraction#cave</div>
              <div className='ok-skip-div'>
                <a className='login-btn1' href="#">Ok</a>
                <a className='login-btn1 skip-btn' href="#">Skip</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Subscribe