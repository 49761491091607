

import axios from '../../../api/axios';


import { FETCH_AD, MY_AD } from './actionsTypes';
import { startLoading, stopLoading } from './actionsTypes';

const token = JSON.parse(localStorage.getItem('token'));
const user_id = JSON.parse(localStorage.getItem('user_id'));


//export const fetchAd = () => (dispatch) => {
export const fetchAd = () => async (dispatch) => {
    console.log('fetchAdfetchAdfetchAd')
    dispatch(startLoading());
    fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboardAds/all`)
        .then((res) => res.json())
        .catch((err) =>
            console.log('err')
            /*  fetch("db.json")
               .then((res) => res.json())
               .then((data) => data.products) */
               
        )
        .then((data) => {
            dispatch({ type: FETCH_AD, payload: data });
            dispatch(stopLoading());

        });
};

export const myAd = () => async (dispatch) => {
   // console.log('myADDMyAAd')
    dispatch(startLoading());
    const config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };

    const bodyParameters = {
        id: user_id
    };
    axios
        //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
        .post("/myAds", bodyParameters, config)
        .then((responseData) => {
            console.log('res.MYSAD');

            if (responseData.status) {
                dispatch({ type: MY_AD, payload: responseData.data });
            } else {
                throw new Error('Error fetching data');
            }
            dispatch(stopLoading());
            //   setLoader(false);
        })
        .catch((err) => {
            //   setLoader(false);
        });

};





