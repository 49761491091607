import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Blogo from '../images/black-logo.png';
import Pro from '../images/pro.png';
import Sponser from '../images/sponsered.png';
import Simg from '../images/s-img.png';
import Fb from '../images/fb.png';
import Filter from '../images/filter.png';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import FacebookAdBox from '../components/FacebookAdBox';
import axios from '../api/axios';
import { toast } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux';
import { myAd } from '../redux/store/actions/adActions';
import { checkImpression } from '../config/fbAdImpression';
import AdFilter from '../components/AdFilter';
import PlanSubscribeChecker from '../components/PlanSubscribeChecker';
function MyAds() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterArr = [];

  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState([]);
  const [revanueValue, setRevanueValue] = useState(0); // Initial value of the slider


  const token = JSON.parse(localStorage.getItem('token'));
  const user_id = JSON.parse(localStorage.getItem('user_id'));

 

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {

    dispatch(myAd());
  }, [dispatch]);
  const products = useSelector((state) => state.myAd);


  const handleRevanueChange = (newValue) => {

    setRevanueValue(newValue);
  };


  function userPageName(username) {
    if (selected.length > 0) {
      return selected.some(function (el) {
        //  console.log('el.pageName', el);
        return el.label === username;
      });
    } else {
      return false;
    }
  }


  { console.log('data', products) }
  return (
    <>

    <PlanSubscribeChecker />
      <DashboarderHeader />
      <div className="container-fluid">
        <div className="row">
          <DashboarderSidebar />
          <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
          <div className="col-md-9 col-lg-10 ml-md-auto px-0 custom-nine-col">
            <main className="container-fluid main-container">
              <AdFilter
                filterArr={filterArr}
                setSelected={setSelected}
                selected={selected}
                handleRevanueChange={handleRevanueChange}
                revanueValue={revanueValue}
              />
              <div className='row'>
                {
                  products.ads.data &&
                  products.ads.data.length > 0 &&
                  products.ads.data.map(
                    (item, i) => {

                      const record = JSON.parse(item.record);

                      const arr = {};
                      arr['label'] = record.pageName ? record.pageName : 'Default';
                      arr['value'] = item.id;
                      //  console.log('checkImpression', checkImpression(item));
                      filterArr.push(arr);

                      // const impressionData = checkImpression(record);
                      const impressionData = [];
                      impressionData['view'] = 0;
                      impressionData['spend'] = 0;
                      impressionData['day'] = 0;
                      if ((userPageName(record.pageName) == true || selected.length == 0) && revanueValue == impressionData['spend']) {
                        return (<>
                          <FacebookAdBox
                            item={item}
                            revanueValue={revanueValue}
                          />
                        </>)
                      }

                    }

                  )

                }


              </div>


              {/*  <div className='row'>
                <div className='col-md-12'>
                  <div className='extension-bar dashbaord-extension'>
                    <div className='extension-left'>
                      <div className='search-input'>
                        <input type='text' placeholder='Search...' />
                        <i className='fa-solid fa-magnifying-glass'></i>
                      </div>
                    </div>
                    <div className='extension-left center'>
                      <label className='amount-spent m-0'>Revenue
                        <div className='range-slider-div'>
                          <input type="range" min='1' max='100' value='25' ng-model="slide" />
                          <span>$22</span>
                        </div>
                      </label>
                    </div>
                    <div className='extension-left right'>
                      <div className='sort-ads'>
                        <a className='sort-ads-a filter-btn' href="#"><img src={Filter} alt='filter' />Filter By</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </main>
          </div>
        </div>
      </div>


    </>
  )
}

export default MyAds