import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'swiper/css';
import 'swiper/css/pagination';
import Footer from "../components/Footer";
import Header from "../components/Header";

function FAQ() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/faq`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(responseData => {
        if (responseData.status) {
          setData(responseData.data);
        } else {
          throw new Error('Error fetching data');
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return (
    <>
    <div className='main-nav'>
    <Header />
    <section className='banner-section pricing-banner'>
      <span className='circle1 small-circle'></span>
      <Container>
        <Row className='justify-content-center'>
            <Col md='8'>
                <h1 className='banner-main-title'>FAQ</h1>
                <p className='banner-p'>It is a long established fact that a reader will be distracted by the readable 
                  content of a page when looking at its layout. </p>
            </Col>
        </Row>
      </Container>
    </section>
    </div>


    <section className='pricing-sec review-section pt-0 mt-5 faq-main-outer'>
       <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='main-title mb-5'>Frequently Asked Questions</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-10 offset-md-1'>

            {data.length > 0 ? (
                  data.map(item => (
                    <div className='faq-div'>
                      <div className='faq-left'>
                      {item.title}
                      </div>
                      <div className='faq-right'>
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No data available</p>
                )}
            </div>
          </div>
       </div>
    </section>
   
    <Footer />
    

    </>
  )
}

export default FAQ