import { FETCH_AD,FILTER_AD } from '../actions/actionsTypes';

const initialState = {
    ads: [],
    filter: '',
};

export default function(state = initialState, action)  {
    console.log('action.typ=',action.type)
    switch (action.type) {
        case FETCH_AD:
            return {...state,ads:action.payload}
        case FILTER_AD:
                return {...state,ads:action.filter}
        default:
            return state;
    }
}

