import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { dateFromMillisecond } from '../config/Configuration';
import Sponser from '../images/sponsered.png';
import Simg from '../images/no-image-256.png';
import blackCircle from '../images/blackCircle.jpg';
import Fb from '../images/fb.png';
import axios from '../api/axios';

import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function PlanSubscribeChecker(props) {

    const navigate = useNavigate();

    const token = JSON.parse(localStorage.getItem('token'));

    useEffect(() => {
            loadCheckPlanStatus();
    }, []);


    const loadCheckPlanStatus = () => {
        //  setLoader(true);

        const config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        const bodyParameters = {
            id: 10
        };
        axios
            //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
            .post("/checkPaidPlanStatus", bodyParameters, config)
            .then((res) => {
                console.log('res.result');
                if (res.data.data == false) {
                    navigate("/subscribe");

                }

                //   setLoader(false);
            })
            .catch((err) => {
                //   setLoader(false);
            });
    };


    return (
        <>

        </>
    )
}

export default PlanSubscribeChecker