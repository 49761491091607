import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Blogo from '../images/black-logo.png';
import Pro from '../images/pro.png';
import D1 from '../images/d1.svg';
import D2 from '../images/d2.svg';
import D3 from '../images/d3.svg';
import D4 from '../images/d4.svg';
import Logout from '../images/logout.svg';
import Sponser from '../images/sponsered.png';
import Simg from '../images/s-img.png';
import Fb from '../images/fb.png';
import Filter from '../images/filter.png';

import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import Following3 from '../images/following3.png';
import Following4 from '../images/following4.png';
import Following5 from '../images/following5.png';
import Following6 from '../images/following6.png';
import Myntra from '../images/myntra.png';
import FollowerAdBox from '../components/FollowerAdBox';
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';
import PlanSubscribeChecker from '../components/PlanSubscribeChecker';


function Following() {
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  useEffect(() => {
    getAllAds();
  }, []);


  const getAllAds = () => {
    dispatch(startLoading());

    fetch(`${process.env.REACT_APP_API_BASE_URL}/followingAds/all`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(responseData => {
        if (responseData.status) {

          setData(responseData.data);
        } else {
          throw new Error('Error fetching data');
        }
        dispatch(stopLoading());
      })
      .catch(error => {
        setError(error);
        dispatch(stopLoading());
      });
  };


  


  return (
    <>
      <PlanSubscribeChecker />
      <DashboarderHeader />
      <div className="container-fluid">
        <div className="row">
          <DashboarderSidebar />

          <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
          <div className="col-md-9 col-lg-10 ml-md-auto px-0 custom-nine-col">
            <main className="container-fluid main-container">
              {console.log('data',data)}
              {data &&
                  Object.keys(data).map(item => (
                  <>

                    <FollowerAdBox
                      item={item}
                      records={data[item]}
                    />

                  </>
                ))
              }

             
            {/*   <div className='row'>
                <div className='col-md-12'>
                  <div className='extension-bar dashbaord-extension'>
                    <div className='extension-left'>
                      <div className='search-input'>
                        <input type='text' placeholder='Search...' />
                        <i className='fa-solid fa-magnifying-glass'></i>
                      </div>
                    </div>
                    <div className='extension-left center'>
                      <label className='amount-spent m-0'>Revenue
                        <div className='range-slider-div'>
                          <input type="range" min='1' max='100' value='25' ng-model="slide" />
                          <span>$22</span>
                        </div>
                      </label>
                    </div>
                    <div className='extension-left right'>
                      <div className='sort-ads'>
                        <a className='sort-ads-a filter-btn' href="#"><img src={Filter} alt='filter' />Filter By</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </main>
          </div>
        </div>
      </div>


    </>
  )
}

export default Following