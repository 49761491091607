import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import Email from '../images/email.svg';
import Lock from '../images/Lock.svg';
import Profile from '../images/Profile.svg';
import axios from '../api/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';
import Spinner from '../config/Spinner';

function  ForgotPassword() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingStatus);

  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleForgotPassword = async (event) => {
    event.preventDefault(); 
    
    if (!email) {
      toast.error('Please fill in all the required fields.');
      return;
    }
    
    try {

      dispatch(startLoading());

      const response = await axios.post('/password/email', { email });
      
      if (response.data.status) {

        toast.success(response.data.message);
        //navigate("/dashboard");
      } else {
        toast.error(response.data.message || "Login failed. Please try again.");
        
      }
      dispatch(stopLoading());
    } catch(e) {
      toast.error(e.message || "An error occurred. Please try again.");
      dispatch(stopLoading());
    }
  }
  return (
    <>
         {loading.loadingStatus? <Spinner /> : <div></div>}

      <div className='login-outer'>
        <span className='circle'></span>
        <div className='login-inner'>
          <a className='back-home' href="/homepage"><i className="fa-solid fa-circle-arrow-left"></i> Back to Home</a>
          <h1 className='login-title'>Forgot Password</h1>
          <p className='desc-text'>Enter the email address that is associated with your account.</p>
          <form onSubmit={handleForgotPassword}>
            <div className='login-input'>
              <input className='custom-input mb-0' type="text" placeholder='Email' name="email" onChange={(e) => setEmail(e.target.value)} value={email} />
              <img className='input-img' src={Email} alt='email' />
            </div>
            <button className='login-btn1' type='submit'>Verify</button>
            <div className='account-text'>Don't have an account? <a href="/signup">Sign Up</a></div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword