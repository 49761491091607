

let X_FB_LSD, X_ASBD_ID, req, sessionID, requestBody, s;
let isSessionStart = false;
let fineArr = [];
let ads = [];
let hideSeconds = 2000;
let userAgent = window.navigator.userAgent;
let referrer = window.location.href;
let CPM = 9;
let filterOn = false;
let duplicateFilterValue = 1;
let delayCounter = 7000;
let userToken = null;


const getData = (pageid) => {

  return fetch("https://ad-archive.nexxxt.cloud/ad/" + pageid)
    .then((response) => {
      if (!response.ok) {
        console.log(`Did not get an ok. got: ${response.statusText}`);
        return 'banned';
      }
      return response.json();
    })
    .then((json) => {
      if (json.eu_total_reach) {
        //   console.log('getImpressionsNew1',json.eu_total_reach);
        return json.eu_total_reach;
      }
    })
    .catch((error) => {
      console.log(`Error getting ad data: ${error.message}`);
      return 'banned';
    })
}

export const checkImpressionFuncOLD = async (ad) => {


  //console.log(checkImpression);
  let i = 0;
  let o = new Array;
  let r;
  let s = ad.adArchiveID;
  let a = ad.pageID;
  let pageid = ad.adArchiveID;
  let item = 0;
  //let res =  getData(pageid)
  let res = getImpressionsNew(pageid);
  //console.log('res-',res);




  const l = await res.then((e) => {
    return e;
  });




  if (typeof l !== "undefined") {
    // console.log('asyncRes-',l);

    //  l = e.eu_total_reach;
    let endDate = ad.isActive ? Date.now() : 1e3 * ad.endDate;
    let n = Math.floor((endDate - 1e3 * ad.startDate) / 864e5) + 1;
    o.push(l / n)
    s = o.reduce(((e, t) => e + t), 0);
    o = (s * (CPM / 1e3)).toFixed(2);
    o = isNaN(o) ? 0 : o;
    var data = [];
    if ("banned" === i) {

      data['views'] = 0;
      data['spend'] = 0;
      data['day'] = 0;
      // console.log(data);

    } else {
      var data = [];
      data['views'] = nFormatter(l);
      data['spend'] = nFormatter((l * (CPM / 1e3)).toFixed(2));
      data['day'] = nFormatter(o);
      //   console.log(data);

    }

  }
  else {

    data['views'] = 0;
    data['spend'] = 0;
    data['day'] = 0;

  }
  return data;

}


export const checkImpressionFunc = async (ad) => {


  //console.log(checkImpression);
  let i = 0;
  let o = new Array;
  let dailyImpressions = new Array;
  let totalImpressions = 0;
  let r;
  let s = ad.adArchiveID;
  let a = ad.pageID;
  let pageid = ad.adArchiveID;
  let item = 0;
  //let res =  getData(pageid)
  let res = getImpressionsNew(pageid);
  //console.log('res-',res);




  const l = await res.then((e) => {
    return e;
  });




  if (typeof l !== "undefined") {
    // console.log('asyncRes-',l);

    //  l = e.eu_total_reach;
 let impressions = l;
 totalImpressions += impressions;
    const isAdActive = ad.isActive;
        const endDate = isAdActive ? Date.now() : 1000 * ad.endDate;
        const numberOfDays = Math.floor((endDate - 1000 * ad.startDate) / 86400000) + 1;
        dailyImpressions.push(impressions / numberOfDays);

   // let endDate = ad.isActive ? Date.now() : 1e3 * ad.endDate;
  //  let n = Math.floor((endDate - 1e3 * ad.startDate) / 864e5) + 1;
  //  o.push(l / n)


  const totalDailyImpressions = dailyImpressions.reduce((sum, daily) => sum + daily, 0);
  const totalSpend = (totalDailyImpressions * (CPM / 1000)).toFixed(2);
  const formattedTotalSpend = isNaN(totalSpend) ? 0 : totalSpend;

   /*  s = o.reduce(((e, t) => e + t), 0);
    o = (s * (CPM / 1e3)).toFixed(2);
    o = isNaN(o) ? 0 : o; */
    var data = [];
    if ("banned" === i) {

      data['views'] = 0;
      data['spend'] = 0;
      data['day'] = 0;
      // console.log(data);

    } else {
      var data = [];
      data['views'] = nFormatter(totalDailyImpressions);
      data['spend'] = nFormatter((totalImpressions * (CPM / 1000)));
      data['day'] = nFormatter(formattedTotalSpend);
      //   console.log(data);

    }

  }
  else {

    data['views'] = 0;
    data['spend'] = 0;
    data['day'] = 0;

  }
  return data;

}


async function checkImpression(ads) {
  // Ensure the necessary conditions are met
  if (Array.isArray(ads) && ads.length > 0 && sessionID && requestBody && s && req) {
    let totalImpressions = 0;
    let dailyImpressions = [];
    const numberOfAds = ads.length;
    console.log(numberOfAds, ":- Number of ads");

    for (let i = 0; i < numberOfAds; i++) {
      const adArchiveID = ads[i]?.adArchiveID;
      const pageID = ads[i]?.pageID;
      console.log(adArchiveID, pageID, ":- adArchiveID, pageID");

      // Fetch impressions data
      const impressions = await getImpressions(adArchiveID, pageID);
      console.log(impressions, ":- Response from getImpressions");

      // Handle various responses
      if (impressions === "banned") {
        return "Facebook has banned your account. To resolve this:\n - Disconnect from your Facebook account\n - Log in with another account or register a new account with a temporary email\n - Reload this page";
      }

      if (impressions === 0) {
        // return "No EU Data";
      } else {
        totalImpressions += impressions;
        
        // Calculate average daily impressions
        const isAdActive = ads[i].isActive;
        const endDate = isAdActive ? Date.now() : 1000 * ads[i].endDate;
        const numberOfDays = Math.floor((endDate - 1000 * ads[i].startDate) / 86400000) + 1;
        dailyImpressions.push(impressions / numberOfDays);
      }
    }

    // Calculate total daily impressions and spend
    const totalDailyImpressions = dailyImpressions.reduce((sum, daily) => sum + daily, 0);
    const totalSpend = (totalDailyImpressions * (CPM / 1000)).toFixed(2);
    const formattedTotalSpend = isNaN(totalSpend) ? 0 : totalSpend;

    if (totalImpressions === 0) {
      return "No EU Data";
    } else {
      return `${nFormatter(totalImpressions)} Views\n${nFormatter((totalImpressions * (CPM / 1000)))}€ Total Spend\n${nFormatter(formattedTotalSpend)}€ / Day`;
    }
  } else {
    return "No data available";
  }
}

function getImpressionsNew1(pageid) {
  return fetch("https://ad-archive.nexxxt.cloud/ad/" + pageid)
    .then((res) => res.json())
    .then((json) => {
      if (json.eu_total_reach) {
        //   console.log('getImpressionsNew1',json.eu_total_reach);
        return json;

      }
      else {
        return "banned";
      }

    }).catch((e => {
      return "banned";
    }
    ));


}


async function getImpressionsNew(pageid) {
  return new Promise(((resolve, reject) => {
    setTimeout((() => {
      fetch("https://ad-archive.nexxxt.cloud/ad/" + pageid)
        .then((res) => res.json())
        .then((json) => {
          if (json.eu_total_reach) {
            // console.log('json',json.eu_total_reach);
            resolve(json.eu_total_reach);

          }
          else {
            resolve("banned");
          }

        }).catch((e => {
          resolve('banned')
        }
        ));
    }), 1e3);
  }
  ));
}







const nFormatter = (e, t = 2) => {
  const n = [{
    value: 1e18,
    symbol: "E"
  }, {
    value: 1e15,
    symbol: "P"
  }, {
    value: 1e12,
    symbol: "T"
  }, {
    value: 1e9,
    symbol: "G"
  }, {
    value: 1e6,
    symbol: "M"
  }, {
    value: 1e3,
    symbol: "K"
  }, {
    value: 1,
    symbol: ""
  }]
    , r = n.find((t => e >= t.value)) || n[n.length - 1];
  return (e / r.value).toFixed(t).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + r.symbol
}


async function getImpressions(e, t) {
  //  console.log('e---',e);
  //console.log('t---',t);

  let doc_id = "6635716889819821";
  return new Promise(((resolve, reject) => {
    setTimeout((() => {

      const requestBody = {
        __req: req,
        __s: s,
        variables: JSON.stringify({
          adArchiveID: e,
          pageID: t,
          country: "ALL",
          sessionID: sessionID,
          source: null,
          isAdNonPolitical: true,
          isAdNotAAAEligible: false,
        }),
        doc_id: doc_id
      };


      /*      requestBody.__req = req;
           requestBody.__s = s;
           requestBody.variables = JSON.stringify({
             adArchiveID: e,
             pageID: t,
             country: "ALL",
             sessionID: sessionID,
             source: null,
             isAdNonPolitical: !0,
             isAdNotAAAEligible: !1
           });
           requestBody.doc_id = doc_id; */
      let i = bodyDataToString(requestBody);
      fetch("https://www.facebook.com/api/graphql/", {
        headers: {
          "User-Agent": userAgent,
          Accept: "*/*",
          "Accept-Language": "fr,fr-FR;q=0.8,en-US;q=0.5,en;q=0.3",
          "Content-Type": "application/x-www-form-urlencoded",
          "X-FB-Friendly-Name": "AdLibraryAdDetailsV2Query",
          "X-FB-LSD": X_FB_LSD,
          "X-ASBD-ID": X_ASBD_ID,
          "Alt-Used": "www.facebook.com",
          "Sec-Fetch-Dest": "empty",
          "Sec-Fetch-Mode": "cors",
          "Sec-Fetch-Site": "same-origin",
          Referer: referrer,
          Origin: "https://www.facebook.com"
        },
        credentials: "same-origin",
        body: i,
        method: "POST"
      }).then((e => e.json()))
        .then((t => {
          console.log('tttttttttttv=', t);
          if (t.errors || t.error) {
            resolve("banned");
          }

          if (t.data && t.data.ad_library_main.ad_details.aaa_info) {
            resolve(t.data.ad_library_main.ad_details.aaa_info.eu_total_reach);
          }

          resolve(!1);

        }
        )).catch((e => {
          resolve(e)
        }
        ));
    }
    ), 1e3);
  }
  ))
}




function bodyDataToString(e) {
  let t = Object.keys(e)
    , n = new Array;
  for (let r = 0; r < t.length; r++)
    n.push(`${t}=${e[t]}`);
  return n.join("&")
}