import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Logo from '../images/logo.svg';
import Commission from '../images/commission.png';
import Footer from "../components/Footer";
import Header from "../components/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SubscriptionPlan = ({ user, user_name }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [user_email, setUserLoggedin] = useState(null);
  const [users_name, setUserLoggedName] = useState(null);
  const [affiliateId, setAffiliateId] = useState(null); // State to store affiliate ID
  const navigate = useNavigate();
  const location = useLocation(); // To get URL query params

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUserLoggedin(storedUser);
    }
  }, []);

  useEffect(() => {
    const storedUserName = JSON.parse(localStorage.getItem('user_name'));
    if (storedUserName) {
      setUserLoggedName(storedUserName);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const affiliateId = queryParams.get('affiliate_id');
    if (affiliateId) {
      setAffiliateId(affiliateId);
    }
  }, [location]);

  const handleToken = async (token, planId, price, user, name) => {
    setLoading(true);

    const stripe = await loadStripe('pk_test_51MWILlSFcCe675zQJnHDHtQK1W3lvhnrGY5DGvgDyjHBBKAnTaFCZvJRLw9TfSauISk1Jf7mUX7Ysxq3jN5UBxP100GbspQhE9');

    fetch(`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        planId,
        email: user,
        name: user_name,
        affiliateId // Include affiliate ID if available
      }),
    })
    .then(response => response.json())
    .then(session => stripe.redirectToCheckout({ sessionId: session.id }))
    .then(result => {
      if (result.error) {
        alert(result.error.message);
        toast.error("Fail to purchase subscription");
      } else {
        alert('Successful'); 
        toast.success('Payment successful!');
        navigate("/dashboard");
      }
    })
    .catch(error => {
      console.error("Error during payment processing", error);
      toast.error("Fail to purchase subscription");
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleBuyNowClick = (item) => {
    setSelectedPlan(item);
    setShowModal(true);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/pricing`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(responseData => {
        if (responseData.status) {
          setData(responseData.data);
        } else {
          throw new Error('Error fetching data');
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className='main-nav'>
        <Header />
        <section className='banner-section pricing-banner'>
          <span className='circle1 small-circle'></span>
          <Container>
            <Row className='justify-content-center'>
              <Col md='8'>
                <h1 className='banner-main-title'>Pricing</h1>
                <p className='banner-p'>
                  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className='our-top-features pt-5 pb-5'>
        <div className='container'>
          <Row className='justify-content-center mt-2'>
            <Col md='8 justify-content-center'>
              <div className='main-title mb-5'>Plan that Match your Needs</div>
            </Col>
          </Row>
          <Row className='mb-5 justify-content-center'>
            <Col md='10'>
              <div className='lifetime-plan'>
                <img className='commission' src={Commission} alt='commission' />
                <div className='row align-items-center'>
                  <div className='col-md-6'>
                    <div className='lifetime-price'>â‚¬497</div>
                  </div>
                  <div className='col-md-6'>
                    <ul className='feature-ul'>
                      <li>
                        <i className="fas fa-circle-check"></i>
                        <span>It is a long established fact that a reader.</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-circle-check"></i>
                        <span>It is a long established fact that.</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-circle-check"></i>
                        <span>It is a long established fact that a reader.</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className='row mb-5'>
            <div className='col-md-8 offset-md-2 col-sm-12'>
              <div className='row'>
                {data.length > 0 ? (
                  data.map(item => (
                    <div className='col-md-6' key={item.id}>
                      <div className={`match-div ${item.interval === 'year' ? 'active' : ''}`}>
                        <span className='monthly-span'>{item.name}</span>
                        <div className='price-title'>
                          {item.interval === 'year' ? (
                            <>
                              â‚¬{item.price} <span className='monthly-price'>â‚¬{(item.price / 12).toFixed(2)}/Monthly</span>
                            </>
                          ) : (
                            <>â‚¬{item.price}</>
                          )}
                        </div>
                        <hr />
                        <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                        <a
                          data-price={item.price}
                          data-user={user_email}
                          data-interval={item.interval}
                          data-name={item.name}
                          data-id={item.stripe_price_id}
                          className='buy-now-btn'
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleBuyNowClick(item);
                          }}
                        >
                          Buy Now
                        </a>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Affiliate Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPlan && (
            <>
              <p>It is a long established fact that a reader will be distracted by readable content.</p>
              <h5>Share this referral link to your friends.</h5>
              <input type="text" value={`example.com?affiliate_id=${user_email}`} readOnly />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Skip
          </Button>
          <Button 
            variant="primary" 
            onClick={() => {
              setShowModal(false);
              handleToken(null, selectedPlan.stripe_price_id, selectedPlan.price, user_email, selectedPlan.name);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SubscriptionPlan;