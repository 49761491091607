import React, { } from 'react';
import { useLocation } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import P1c from '../images/p1c.png';
import P2c from '../images/p2c.png';
import P3c from '../images/p3c.png';
import P4c from '../images/p4c.png';
import P1 from '../images/p1.png';
import P2 from '../images/p2.png';
import P3 from '../images/p3.png';
import P4 from '../images/p4.png';

function UserProfileSidebar() {
  const location = useLocation();

  const getActiveClass = (path) => {
    return location.pathname === path ? 'active' : '';
};
  return (
    <>
     <ul className='profile-ul'>
              <li className={getActiveClass('/edit-profile')}><a href="/edit-profile">
                <img className='white-img' src={P1} alt='p1' />
                <img className='colored' src={P1c} alt='p1c' />
              </a></li>
              <li className={getActiveClass('/change-password')}><a href="/change-password">
                <img className='white-img' src={P2} alt='p2' />
                <img className='colored' src={P2c} alt='p2c' />
                </a></li>
              <li className={getActiveClass('/general-affilate-link')}><a target='_blank' href="https://spyderads.firstpromoter.com/">
                <img className='white-img' src={P3} alt='p3' />
                <img className='colored' src={P3c} alt='p3c' />
                </a></li>
              <li className={getActiveClass('/bank-account')}><a href="/bank-account">
                <img className='white-img' src={P4} alt='p4' />
                <img className='colored' src={P4c} alt='p4c' />
                </a></li>
             </ul>
  
    </>
  )
}

export default UserProfileSidebar