import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Logo from '../images/logo.svg';
import BlogoSvg from '../images/white_logo.svg';
import { useSelector, useDispatch } from 'react-redux';

import 'swiper/css';
import 'swiper/css/pagination';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import Spinner from '../config/Spinner';
import axios from '../api/axios';

function Header() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const loading = useSelector((state) => state.loadingStatus);
  const token = JSON.parse(localStorage.getItem('token'));

  const handleLogout = () => {
    localStorage.removeItem('user');
    logout();
   // navigate('/login');
  };


  const logout = () => {
    //  setLoader(true);
  
      const config = {
          headers: {
              'Authorization': 'Bearer ' + token
          }
      };
  
      const bodyParameters = {
          id: 10
      };
      axios
          //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
          .post("/user/logout", bodyParameters, config)
          .then((res) => {
              console.log('res.result');
              console.log(res.data.result);
              navigate('/login');
           //   setLoader(false);
          })
          .catch((err) => {
           //   setLoader(false);
          });
  };


  return (
    <>
     {loading.loadingStatus? <Spinner /> : <div></div>}
    <Navbar expand="lg" className="bg-body-tertiary custom-navbar">
      <Container>
        <Navbar.Brand href="/homepage"><img src={BlogoSvg} className="main-header-logo" alt="Logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 nav-inner"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/pricing">Pricing</Nav.Link>
            <Nav.Link href="/about-us">About Us</Nav.Link>
            <Nav.Link href="/learn">Learn</Nav.Link>
            {user ? (
                <>
                  <Nav.Link className='login-btn' href="/dashboard">Dashboard</Nav.Link>
                  <Nav.Link className='login-btn' target='_blank' href="https://spyderads.firstpromoter.com/">Affiliate</Nav.Link>
                  <Nav.Link className='signup-btn' onClick={handleLogout}>Logout</Nav.Link>                 
                </>
              ) : (
                <>
                  <Nav.Link className='login-btn' href="/login">Login</Nav.Link>
                  <Nav.Link className='signup-btn' href="/signup">Sign Up</Nav.Link>
                </>
              )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    

    </>
  )
}

export default Header