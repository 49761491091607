
// reducer.js
const initialState = {
    loadingStatus: false,
};

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'START_LOADING':
            return { ...state, loadingStatus: true };
        case 'STOP_LOADING':
            return { ...state, loadingStatus: false };
        default:
            return state;
    }
};

export default loadingReducer;

