import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import FacebookAdBox from '../components/FacebookAdBox';
import { useSelector, useDispatch } from 'react-redux';



import axios from '../api/axios';

import { fetchAd } from '../redux/store/actions/adActions';
import { MultiSelect } from "react-multi-select-component";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { checkImpressionFunc } from '../config/fbAdImpression';
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';

import FollowerAdBox from '../components/FollowerAllAdBox';
//import { getAdsLibraryData } from '../config/facebookAdsService';



function AllFollowing(props) {
    /* range */
    const [revanueValue, setRevanueValue] = useState(0); // Initial value of the slider

    const [searchTerm, setSearchTerm] = useState('');
    const [adsData, setAdsData] = useState([]);

    const token = JSON.parse(localStorage.getItem('token'));


    const handleChange = (newValue) => {
        setRevanueValue(newValue);
    };



    const [isActive, setIsActive] = useState(false);
    const [data, setData] = useState([]);
    //const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selected, setSelected] = useState([]);
    const [fiterOption, setFilterOption] = useState([]);
    const [products, setProducts] = useState([]);

    const dispatch = useDispatch();




    const toggleSidebar = () => {
        setIsActive(!isActive);
    };
    const { id } = useParams();


    useEffect(() => {
       
        getFollowingSingleData();
        },[]);
      





    const getFollowingSingleData =  () => {
        dispatch(startLoading());

        const config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        const bodyParameters = {

            pageName: id
        };
        
         axios
            //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
            .post("/following/getFollowingSingleData", bodyParameters, config)
            .then((res) => {
                console.log('res.result', res.data);

                setData(res.data.data);
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(stopLoading());
            });
    };
    console.log('data', data);
    const filterArr = [];


    function userPageName(username) {
        if (selected.length > 0) {
            return selected.some(function (el) {
                //  console.log('el.pageName', el);
                return el.label === username;
            });
        } else {
            return false;
        }
    }



    return (
        <>



            <DashboarderHeader />
            <div className="container-fluid">
                <div className="row">
                    <DashboarderSidebar />


                    <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
                    <div className="col-md-9 col-lg-10 ml-md-auto px-0 custom-nine-col">
                        <main className="container-fluid main-container">

                        <FollowerAdBox
                    pageName={id}
                      records={data}
                    />

                        </main>
                    </div>
                </div>
            </div>


        </>
    )
}


export default AllFollowing