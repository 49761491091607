import React, { useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useNavigate, useLocation } from 'react-router-dom';
import D1 from '../images/d1.svg';
import D2 from '../images/d2.svg';
import D3 from '../images/d3.svg';
import D4 from '../images/d4.svg';
import Logout from '../images/logout.svg';
import google_chrome_gray from '../images/google_chrome_gray.svg';
function UserDashboardSidebar() {
    const navigate = useNavigate();
    const location = useLocation();

    const user = JSON.parse(localStorage.getItem('user'));

    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/login');
    };

    const [isActive, setIsActive] = useState(false);
    const toggleSidebar = () => {
        setIsActive(!isActive);
    };

    const getActiveClass = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <>
            <div className={`col-md-3 col-lg-2 px-0 position-fixed h-100 bg-white shadow-sm sidebar ${isActive ? 'active' : ''}`} id="sidebar">
                <div className="list-group rounded-0 sidebar-left">
                    <a href="/dashboard" className={getActiveClass('/dashboard')}>
                        <img src={D1} alt='d1' />
                    </a>
                    <a href="/my-ads" className={getActiveClass('/my-ads')}>
                        <img src={D2} alt='d2' />
                    </a>
                    <a href="/following" className={getActiveClass('/following')}>
                        <img src={D3} alt='d3' />
                    </a>
                    <a href="/subscribe" className={getActiveClass('/subscribe')}>
                        <img src={D4} alt='d4' />
                    </a>
                    <a target="_blank" href="https://chromewebstore.google.com/detail/spyder-ads-v10/acgjmlhlkokkgomeaignoaebahennfbp?authuser=0&hl=en&pli=1" className={getActiveClass('/crome')}>
                        <img src={google_chrome_gray} alt='d4' />
                    </a>
                    
                    <a className={`logout ${getActiveClass('/logout')}`} onClick={handleLogout} href="#">
                        <img src={Logout} alt='logout' />
                    </a>
                </div>
            </div>
        </>
    );
}

export default UserDashboardSidebar;
