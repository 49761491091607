import React, { useState, useEffect } from 'react';
import Blogo from '../images/black-logo.png';
import Pro from '../images/pro.png';
import D1 from '../images/d1.svg';
import D2 from '../images/d2.svg';
import D3 from '../images/d3.svg';
import D4 from '../images/d4.svg';
import Logout from '../images/logout.svg';
import P1 from '../images/p1.png';
import P2 from '../images/p2.png';
import P3 from '../images/p3.png';
import P4 from '../images/p4.png';
import P1c from '../images/p1c.png';
import P2c from '../images/p2c.png';
import P3c from '../images/p3c.png';
import P4c from '../images/p4c.png';
import ProfileImg from '../images/profile-img.png';
import Camera from '../images/Camera.png';
import Lock from '../images/Lock.svg';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import UserProfileSidebar from '../components/UserProfileSidebar';
import axios from '../api/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {  app_base_url } from "../config/Configuration";
import copy from 'copy-to-clipboard';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Modal, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';


import 'react-tabs/style/react-tabs.css';

function GeneralAffilateLink() {
  const [isActive, setIsActive] = useState(false);




  const [errorMessage, setErrorMessage] = useState("");
  const [affiliateLink, setAffiliateLink] = useState('');

  const [totalDiscount, setTotalDiscount] = useState(0);
  const [data, setData] = useState([]);
  const [affilateModelStatus, setAffilateModelStatus] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setEmail(storedUser);
    }
  }, []);
  const user_id = localStorage.getItem('user_id');
  const token = JSON.parse(localStorage.getItem('token'));

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('affilate_link'));

    if (user_id) {
      //setAffiliateLink(app_base_url + 'signup/?ref=' + encryptData(user_id));
      setAffiliateLink(app_base_url + 'signup/?ref=000' + user_id);
    }

    getAffilateDetail();
  }, []);



  const getAffilateDetail = () => {
    //  setLoader(true);

    const config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    };

    const bodyParameters = {
      id: user_id
    };
    axios
      //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
      .post("/affilate/affilateDetail", bodyParameters, config)
      .then((res) => {
        console.log('res.result', res.data.total);

        setTotalDiscount(res.data.total);
        setTotalAmount(res.data.total);
        setData(res.data.data);
        //   setLoader(false);
      })
      .catch((err) => {
        //   setLoader(false);
      });
  };



  const toggleSidebar = () => {
    setIsActive(!isActive);
  };
  const copyToClip = () => {
    copy(affiliateLink);
  }

  const handleAffilateLink = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('/affiliate/link', { email });

      if (response.data.status) {
        localStorage.setItem('affilate_link', JSON.stringify(response.data.data.url));
        setAffiliateLink(response.data.data.url);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      toast.error(e.message || "An error occurred. Please try again.");
    }
  }

  const hideAffilateModel = () => {
    setAffilateModelStatus(false);
  };
  const handleAffilateSubmit = () => {
    if (!name || !email || !number || !bankName || !bankCode || !bankAccountNumber) {

      toast.error("Please fill in all the required fields.");
      setLoading(false);

    }
    else {
      const config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      };

      const bodyParameters = {
        name: name,
        email: email,
        number:number,
        bankName:bankCode,
        bankCode:bankCode,
        bankAccountNumber:bankAccountNumber,
        paymentLink:paymentLink,
        totalAmount:totalAmount
      };
      axios
        //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
        .post("/affilate/redeem-request", bodyParameters, config)
        .then((res) => {
          if(res.data.status==true)
          {
              toast.success(res.data.message);
          }
          else
          {
            toast.error(res.data.message);
          }
          setLoading(false)
          hideAffilateModel();
         
          //   setLoader(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  return (
    <>
      <DashboarderHeader />
      <div className="container-fluid">
        <div className="row">
          <DashboarderSidebar />
          <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
          <div className="col-md-9 col-lg-10 ml-md-auto px-0 custom-nine-col">
            <main className="container-fluid main-container">
              <div className='profile-div'>
                <UserProfileSidebar />
                <div className='login-inner affilate-link-div'>


                  <div class="row">

                    <div class="col-xxl-4 col-md-6">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title">Commision <span>| Total</span></h5>

                          <div class="d-flex align-items-center">
                            <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                              <i class="bi bi-doller"></i>
                            </div>
                            <div class="ps-3">
                              <h3>{totalDiscount}</h3>
                              {/*  <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xxl-4 col-md-6">
                      <button className='login-btn1' onClick={() => { setAffilateModelStatus(true); }} >Redeem Now</button>
                    </div>
                  </div>







                  <Tabs>
                    <TabList>
                      <Tab>Get Affiliate link</Tab>
                      <Tab>Transaction/Report</Tab>
                    </TabList>

                    <TabPanel>
                      <h1 class="login-title">Generate Affiliate Link</h1>
                      <p class="desc-text">It is a long established fact that a reader will be distracted by the readable content.</p>
                      <div className='profile-inner edit-profile-div change-password'>
                        <div className='profile-inputs'>
                          <form onSubmit={handleAffilateLink}>
                            <div className='login-input'>
                              <input className='custom-input affilate-link-input' id="affilate-link-input" value={affiliateLink} type="text" placeholder='Generate Affiliate Link' />

                              <a className='copy-link' onClick={copyToClip} href='#'><i className="fa-solid fa-clone"></i></a>
                            </div>

                            {/* <button className='login-btn1' type='submit'>Generate</button> */}
                          </form>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <h2>Report</h2>
                      <table class="table">
                        <thead>
                          <tr>
                            
                           
                            <th scope="col">Plan Price</th>
                            <th scope="col">Commision (in %)</th>
                            <th scope="col">Commision in Price (Credit)</th>
                            <th scope="col">Commision in Price (Debit)</th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length > 0 ? (
                            data.map(item => (


                              <>
                                <tr>
                                 
                                  
                                  <td>{item.plan_price}</td>
                                  <td>{item.discount_percent}</td>
                                  <td> {item.credit}</td>
                                  <td> {item.debit}</td>
                                  <td>2004-07-03</td>
                                </tr>

                              </>
                            ))
                          ) : (
                            <p>No data available</p>
                          )}


                        </tbody>
                      </table>
                    </TabPanel>
                  </Tabs>

                </div>
              </div>
            </main>
          </div>
        </div>
      </div>



      <Modal show={affilateModelStatus} onHide={hideAffilateModel}>
        {/*  <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className='affilate-link'>Redeem Request</div>

          <form onSubmit={handleAffilateSubmit}>
            <div className='login-input'>
              <input className='custom-input' name="name" onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder='Contact Name' />
            </div>
            <div className='login-input'>
              <input className='custom-input' name="email" onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder='Contact Email' />
            </div>
            <div className='login-input'>
              <input className='custom-input' name="phoneNumber" onChange={(e) => setNumber(e.target.value)} value={number} type="text" placeholder='Contact Phone Number' />
            </div>
            <div className='login-input'>
              <input className='custom-input' name="bankName" onChange={(e) => setBankName(e.target.value)} value={bankName} type="text" placeholder='Bank Name' />
            </div>
            <div className='login-input'>
              <input className='custom-input' name="bankCode" onChange={(e) => setBankCode(e.target.value)} value={bankCode} type="text" placeholder='Bank Code' />
            </div>
            <div className='login-input'>
              <input className='custom-input' name="bankAccountNumber" onChange={(e) => setBankAccountNumber(e.target.value)} value={bankAccountNumber} type="text" placeholder='Bank Account Number' />
            </div>
            <div className='login-input'>
              <input className='custom-input' name="paymentLink" onChange={(e) => setPaymentLink(e.target.value)} value={paymentLink} type="text" placeholder='Payment Link Such Stripe/Paypal' />
            </div>
            <div className='login-input'>
              <input className='custom-input' disabled name="totalAmount" onChange={(e) => totalAmount(e.target.value)} value={totalAmount} type="text" placeholder='' />
            </div>
            <button className='login-btn1' onClick={handleAffilateSubmit} type='button'>
              {
                !loading ? "Submit" :
                  <Spinner animation="border" role="status" size='sm'>
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
              }
            </button>
          </form>

        </Modal.Body>
        {/*  <Modal.Footer>
          <Button variant="secondary" onClick={hideAffilateModel}>
            Close
          </Button>
          <Button variant="primary" onClick={hideAffilateModel}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>


    </>
  )
}

export default GeneralAffilateLink