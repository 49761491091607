import React, { useEffect, useState } from "react";
import Blogo from '../images/black-logo.png';
import Pro from '../images/pro.png';
import D1 from '../images/d1.svg';
import D2 from '../images/d2.svg';
import D3 from '../images/d3.svg';
import D4 from '../images/d4.svg';
import Logout from '../images/logout.svg';
import P1 from '../images/p1.png';
import P2 from '../images/p2.png';
import P3 from '../images/p3.png';
import P4 from '../images/p4.png';
import Edit from '../images/Edit.png';
import ProfileImg from '../images/profile-img.png';
import P1c from '../images/p1c.png';
import P2c from '../images/p2c.png';
import P3c from '../images/p3c.png';
import P4c from '../images/p4c.png';
import axios from '../api/axios';

function Profile() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const token = JSON.parse(localStorage.getItem('token'));

  useEffect(() => {
    getProfile();


}, []);

const getProfile = () => {
  //  setLoader(true);

    const config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };

    const bodyParameters = {
        id: 10
    };
    axios
        //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
        .post("/user/profile", bodyParameters, config)
        .then((res) => {
            console.log('res.result');
            console.log(res.data.result);

         //   setLoader(false);
        })
        .catch((err) => {
         //   setLoader(false);
        });
};



  return (
    <>
    <nav className="w-100 d-flex px-4 py-2 mb-4 shadow-sm">
      <button className="btn py-0 d-lg-none" id="open-sidebar" onClick={toggleSidebar}>
        <span className="bi bi-list text-primary h3"></span>
      </button>
      <div className='dashbaord-header'>
        <a href="#"><img className='dashbaord-logo' src={Blogo} alt='blogo' /></a>
        <div className='dashbaord-title'>Profile</div>
        <div className="dropdown">
          <button className="btn py-0 d-flex align-items-center" id="logout-dropdown" data-toggle="dropdown" aria-expanded="false">
            <img src={Pro} alt='profile' />
            <span className="user-name">John Doe</span>
            <span className="bi bi-chevron-down ml-1 small"></span>
          </button>
          <div className="dropdown-menu dropdown-menu-right border-0 shadow-sm" aria-labelledby="logout-dropdown">
            <a className="dropdown-item" href="#">Logout</a>
            <a className="dropdown-item" href="#">Settings</a>
          </div>
        </div>
      </div>
    </nav>
    <div className="container-fluid">
      <div className="row">
        <div className={`col-md-3 col-lg-2 px-0 position-fixed h-100 bg-white shadow-sm sidebar ${isActive ? 'active' : ''}`}  id="sidebar">
          <div className="list-group rounded-0 sidebar-left">
            <a href="#">
              <img src={D1} alt='d1' />
            </a>
            <a href="#">
            <img src={D2} alt='d2' />
            </a>
            <a href="#">
            <img src={D3} alt='d3' />
            </a>
            <a href="#" className="active">
            <img src={D4} alt='d4' />
            </a>
            <a className='logout' href="#">
            <img src={Logout} alt='logout' />
            </a>
          </div>
        </div>
        <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
        <div className="col-md-9 col-lg-10 ml-md-auto px-0 custom-nine-col">
          <main className="container-fluid main-container">
           <div className='profile-div'>
           <ul className='profile-ul'>
              <li className='active'><a href="#">
                <img className='white-img' src={P1} alt='p1' />
                <img className='colored' src={P1c} alt='p1c' />
              </a></li>
              <li><a href="#">
                <img className='white-img' src={P2} alt='p2' />
                <img className='colored' src={P2c} alt='p2c' />
                </a></li>
              <li><a href="#">
                <img className='white-img' src={P3} alt='p3' />
                <img className='colored' src={P3c} alt='p3c' />
                </a></li>
              <li><a href="#">
                <img className='white-img' src={P4} alt='p4' />
                <img className='colored' src={P4c} alt='p4c' />
                </a></li>
             </ul>
             <div className='login-inner'>
               <div className='edit-profile'>
                 <a href='#'><img src={Edit} alt='edit' />Edit Profile</a>
               </div>
               <h1 class="login-title">Profile</h1>
               <p class="desc-text">It is a long established fact that a reader will be distracted by the readable content.</p>
               <div className='profile-inner'>
                <div className='profile-img'>
                   <img className='profile-img' src={ProfileImg} alt='profile-img' />
                </div>
                <div className='profile-inputs'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className='profile-label'>First Name</label>
                      <div className='profile-input'>John</div>
                    </div>
                    <div className='col-md-6'>
                      <label className='profile-label'>Last Name</label>
                      <div className='profile-input'>Doe</div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className='profile-label'>Email</label>
                      <div className='profile-input'>john@gmail.com</div>
                    </div>
                  </div>
                </div>
               </div>
             </div>
           </div>
          </main>
        </div>
      </div>
    </div>
    

    </>
  )
}

export default Profile