import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import W1 from '../images/w1.png';
import W2 from '../images/w2.png';
import W3 from '../images/w3.png';
import Step11 from '../images/step11.png';
import Step22 from '../images/step22.png';
import Step33 from '../images/step33.png';
import Step44 from '../images/step44.png';



import 'swiper/css';
import 'swiper/css/pagination';

import Footer from "../components/Footer";
import Header from "../components/Header";

function AboutUs() {
  return (
    <>
    <div className='main-nav'>
    <Header />
    <section className='banner-section pricing-banner'>
      <span className='circle1 small-circle'></span>
      <Container>
        <Row className='justify-content-center'>
            <Col md='8'>
                <h1 className='banner-main-title'>About Us</h1>
                <p className='banner-p'>Discover the story behind the creation of SpyderAds. 
 </p>
            </Col>
        </Row>
      </Container>
    </section>
    </div>

    <section className='our-top-features pt-5 pb-5'>
       <div className='container'>
          <Row className='align-items-center'>
            <Col md='12'>
              <div className='about-us-divv'>
                <div className='about-left'>
                    <div className='main-title mb-2'>Our Vision</div>
                    <p>Our vision is to revolutionize E-Commerce by making winning products easily accessible to everyone, especially beginners. </p>
                    
                    <ul className='feature-ul mt-4'>
                      <li>
                        <i class="fas fa-circle-check"></i>
                        <span>We quickly realized that very few people succeed in E-Commerce simply because they don't know how to find winning products before anyone else. </span>
                      </li>
                      <li>
                        <i class="fa-solid fa-circle-check"></i>
                        <span>Facebook Ads Library revolutionized this, and we immediately thought of exploiting it. 
                        </span>
                      </li>
                      <li>
                        <i class="fa-solid fa-circle-check"></i>
                        <span>The problem is that only an expert can really find good products on the Facebook Ads Library simply because there's no information and you have to analyze everything. It's impossible for a beginner. That's where SpyderAds comes in, enabling anyone to find winning products easily and in just a few clicks, by directly analyzing the amounts spent, the number of active ads and a whole lot more.</span>
                      </li>
                    </ul>
                </div>
                <div className='about-left ambition-left'>
                    <div className='main-title mb-2'>Our Ambitions</div>
                    <p>Our ambition is to multiply the success of E-Commerce beginners by 20. Traditional product search tools are solved, and no longer work. </p>
                    <ul className='feature-ul mt-4'>
                      <li>
                        <i class="fas fa-circle-check"></i>
                        <span>We're convinced that with SpyderAds we can revolutionize E-Commerce and product search techniques to maximize your chances of success and become financially free. </span>
                      </li>
                      <li>
                        <i class="fa-solid fa-circle-check"></i>
                        <span>The beta phase has already shown the power of the tool, and now it's going to be possible for you to become financially free with the help of SpyderAds.</span>
                      </li>
                      
                    </ul>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='align-items-center mt-5 mb-5 section-space d-none hide'>
            <Col md='12'>
               <div className='row justify-content-center mt-5'>
                  <Col md='8'>
                    <div className='features-right'>
                        <div className='feature-upper justify-content-center mb-5'>
                          <span>Lorem Ipsum is Simple Dummy Text</span>
                        </div>
                    </div>
                  </Col>
               </div>
               <div className='row'>
                 <div className='col-md-4'>
                   <div className='worldwide'>
                     <img src={W1} alt='w1' />
                     <div className='worldwide-title'>Worldwide</div>
                     <p>It is a long established fact that a reader will be distracted by the readable.</p>
                   </div>
                 </div>
                 <div className='col-md-4'>
                   <div className='worldwide center'>
                     <img src={W2} alt='w2' />
                     <div className='worldwide-title'>Money</div>
                     <p>It is a long established fact that a reader will be distracted by the readable.</p>
                   </div>
                 </div>
                 <div className='col-md-4'>
                   <div className='worldwide right'>
                     <img src={W3} alt='w3' />
                     <div className='worldwide-title'>Download</div>
                     <p>It is a long established fact that a reader will be distracted by the readable.</p>
                   </div>
                 </div>
               </div>
            </Col>
          </Row>
       </div>
    </section>

    <section className='pricing-sec steps-section bg-none'>
       <div className='container'>
          <div className='row'>
             <div className='col-md-8 offset-md-2 col-sm-12'>
                <div className='main-title mb-5'>Starts with 4 Easy Steps</div>
             </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='step-div'>
                <div className='step-count'>
                  01
                </div>
                <div className='step-object'>
                   <img src={Step11} alt='step11' />
                </div>
                <div className='step-info'>
                  <div className='step-info-title'>Login to SpyderAds</div>
                  <p>Start by logging in to SpyderAds or creating your account. </p>
                </div>
              </div>
              <div className='step-div flex-direction'>
                <div className='step-info text-left'>
                  <div className='step-info-title blue'>Visit The Facebook Ad Library</div>
                  <p>Visit the Facebook Ad Library to discover the power of SpyderAds and 
                    start finding your winners.</p>
                </div>
                <div className='step-object'>
                   <img src={Step22} alt='step22' />
                </div>
                <div className='step-count blue text-left'>
                  02
                </div>
              </div>
              <div className='step-div'>
                <div className='step-count green'>
                  03
                </div>
                <div className='step-object'>
                   <img src={Step33} alt='step33' />
                </div>
                <div className='step-info'>
                  <div className='step-info-title green'>See Your Ads In the Dashboard</div>
                  <p>Save your favorite ads and find them directly in your dashboard from your account 
                    to keep an eye on them.</p>
                </div>
              </div>
              <div className='step-div flex-direction'>
                <div className='step-info text-left'>
                  <div className='step-info-title yellow'>Discover New Ads in Our Ads Library</div>
                  <p>SpyderAds analyzes the ads you see every day and displays them directly in SpyderAds, 
                    without having to go to the Facebook Ad Library. </p>
                </div>
                <div className='step-object'>
                   <img src={Step44} alt='step44' />
                </div>
                <div className='step-count yellow text-left'>
                  04
                </div>
              </div>
            </div>
          </div>
          {/* <div className='row'>
              <div className='col-md-4'>
                <div className='step1'>
                    <img src={Step1} alt='step1' />
                    <div className='step-title'>Step 1</div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='step1 step2'>
                    <img src={Step2} alt='step2' />
                    <div className='step-title'>Step 2</div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='step1'>
                    <img src={Step3} alt='step3' />
                    <div className='step-title'>Step 3</div>
                </div>
              </div>
          </div> */}
       </div>
    </section>


    <section className='pricing-sec review-section pt-0'>
       <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='main-title mb-5'>Frequently Asked Questions</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-10 offset-md-1'>
              <div className='faq-div'>
                <div className='faq-left'>
                How will SpyderAds help me find winners?
                </div>
                <div className='faq-right'>
                SpyderAds allows you to analyze all E-Commerce stores in record time, while showing you the amounts spent on advertising for European ads. You'll have all the information you need 
                to validate the potential of the product or store you've found.
                </div>
              </div>
              <div className='faq-div'>
                <div className='faq-left'>
                How do you estimate European ad spend? 
                </div>
                <div className='faq-right'>
                We simply use the coverage data provided by Facebook, and then, thanks to our analyses and algorithms, 
                we can estimate the amount spent on advertising.
                </div>
              </div>
              <div className='faq-div'>
                <div className='faq-left'>
                Can I download the advertising content of the ads I've found?
                </div>
                <div className='faq-right'>
                Thanks to SpyderAds, you can download any advertising content 
                in just 1 click via our "Download" button.
                </div>
              </div>
              <div className='faq-div'>
                <div className='faq-left'>
                What features do you offer with SpyderAds?
                </div>
                <div className='faq-right'>
                We currently offer 3 main features, and we plan to add many more in the near future. 
                Here are the 3 features you'll find:
                <ul className='feature-ul mt-4'>
                    <li>
                      <i class="fas fa-circle-check"></i>
                      <span>The ability to filter ad content by "Shopify Store" and "Dropshipping".</span>
                    </li>
                    <li>
                      <i class="fa-solid fa-circle-check"></i>
                      <span>The ability to view the amount spent on European ads.</span>
                    </li>
                    <li>
                      <i class="fa-solid fa-circle-check"></i>
                      <span>1-click download of ad content.</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='read-more-div'>
              <a className='buy-now-btn read-more' href="/faq">Read More</a>
              </div>
            </div>
          </div>
       </div>
    </section>
   
    <Footer />
    

    </>
  )
}

export default AboutUs