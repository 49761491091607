import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { dateFromMillisecond } from '../config/Configuration';
import Sponser from '../images/sponsered.png';
import Simg from '../images/no-image-256.png';
import blackCircle from '../images/blackCircle.jpg';
import Fb from '../images/fb.png';
import axios from '../api/axios';

import { ToastContainer, toast } from 'react-toastify';
import Amazon from '../images/amazon.png';
import Following1 from '../images/following1.png';
import Following2 from '../images/following2.png';
import Following3 from '../images/following3.png';
import Following4 from '../images/following4.png';
import Following5 from '../images/following5.png';
import Following6 from '../images/following6.png';
import Myntra from '../images/myntra.png';

function FollowerAdBoxCardCategory(props) {


    const token = JSON.parse(localStorage.getItem('token'));

    const [savedStatus, setSavedStatus] = useState(false);
    const [brandImage, setBrandImage] = useState(false);
    const [mediaImage, setMediaImage] = useState(false);
    const record = JSON.parse(props.records[0].record);

    useEffect(() => {
        loadDefauldMedia();

    }, []);


     const loadDefauldMedia = () => {
        const tempBrandImg =props.record && props.record.snapshot.page_profile_picture_url ? props.record.snapshot.page_profile_picture_url : '';
        setBrandImage(tempBrandImg);

        const tempMediaImg = props.record && props.record.snapshot ? props.record.snapshot : '';
        setMediaImage(tempMediaImg);
    }; 

/* 
    const loadDefauldMedia = () => {
        let tempBrandImg = '';//record.snapshot ? record.snapshot.page_profile_picture_url : '';

        if (props.ad.ad_logo) {
            tempBrandImg = process.env.REACT_APP_BACKEND_BASE_URL+'/' + props.ad.ad_logo;

        }
        else if ( props.record.snapshot.page_profile_picture_url) {
            tempBrandImg =  props.record.snapshot.page_profile_picture_url;

        }
     
        setBrandImage(tempBrandImg);

      
    };
 */


    const onErrorBrandImage = () => {
        setBrandImage(blackCircle);
    };

    const onErrorMediaImage = () => {
        setMediaImage(Simg);
    };


    return (
        <>
                           <img src={brandImage}  onError={() => onErrorBrandImage()}  alt='sponser' />

<div className='amazon-title'>{props.item}</div>
<p>{props.records.length} new ads</p>
<p>Updated {dateFromMillisecond(props.records[0].startDate)}</p>
 <a href={`/all-following/${props.records[0].pageName}`} className='login-btn1 view-more-followings'>View More</a> 
        </>
    )
}

export default FollowerAdBoxCardCategory