import React, { useState, useEffect } from 'react';
import Blogo from '../images/black-logo.png';
import BlogoSvg from '../images/colored_logo.svg';
import { useSelector, useDispatch } from 'react-redux';

import LogoPng from '../images/logoPng.png';
import 'swiper/css';
import 'swiper/css/pagination';
import { useNavigate, useLocation } from 'react-router-dom';
import Pro from '../images/pro.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../config/Spinner';
import axios from '../api/axios';



function UserDashboarderHeader() {

  const token = JSON.parse(localStorage.getItem('token'));

  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingStatus);
  useEffect(() => {
      const query = new URLSearchParams(location.search);
      const status = query.get('status');
      const message = query.get('message');
      
      if (status === 'true' && message) {
          toast.success(message);
      }
  }, [location]);

    const navigate = useNavigate();
  
    const [user_name, setUser] = useState(null);
    const [pageTitle, setPageTitle] = useState('Dashboard');


    useEffect(() => {

      const storedUser = JSON.parse(localStorage.getItem('user_name'));
      if (storedUser) {
        setUser(storedUser);
      }
  
      // Update page title when location changes
      const handlePageTitle = () => {
        const path = location.pathname;
        switch (path) {
          case '/homepage':
            setPageTitle('Home');
            break;
          case '/my-ads':
            setPageTitle('My Ads');
            break;
            case '/subscribe':
            setPageTitle('Subscribe');
            break;
            case '/edit-profile':
            setPageTitle('Edit Profile');
            break;
            case '/following':
            setPageTitle('Following');
            break;
            case '/change-password':
            setPageTitle('Change Password');
            break;
            case '/general-affilate-link':
            setPageTitle('Generate Affiliate Link');
            break;
            case '/bank-account':
            setPageTitle('Bank Account');
            break;
          // Add more cases for other pages as needed
          default:
            setPageTitle('Dashboard');
            break;
        }
      };
  
      handlePageTitle(); // Call initially
      return () => {
        // Cleanup
      };
    }, [location]);
  
    const user = JSON.parse(localStorage.getItem('user'));
  
    const handleLogout = () => {
      localStorage.clear();
      localStorage.removeItem('user');
      logout();
      
    };
  
    const [isActive, setIsActive] = useState(false);
    const toggleSidebar = () => {
      setIsActive(!isActive);
    };




    const logout = () => {
      //  setLoader(true);
    
        const config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
    
        const bodyParameters = {
            id: 10
        };
        axios
            //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
            .post("/user/logout", bodyParameters, config)
            .then((res) => {
                console.log('res.result');
                console.log(res.data.result);
                navigate('/login');
             //   setLoader(false);
            })
            .catch((err) => {
             //   setLoader(false);
            });
    };

  return (
    <>
    {loading.loadingStatus? <Spinner /> : <div></div>}
    <nav className="w-100 d-flex px-4 py-2 mb-4 shadow-sm">
      <button className="btn py-0 d-lg-none" id="open-sidebar" onClick={toggleSidebar}>
        <span className="bi bi-list text-primary h3"></span>
      </button>
      <div className='dashbaord-header'>
        <a href="/homepage"><img className='dashbaord-logo' src={BlogoSvg} alt='blogo' /></a>
        <div className='dashbaord-title'>{pageTitle}</div>
        <div className="dropdown">
          <button className="btn py-0 d-flex align-items-center" id="logout-dropdown" data-toggle="dropdown" aria-expanded="false">
            {/* <img src={Pro} alt='profile' /> */}
            <span className="user-name">{user_name}</span>
            <span className="bi bi-chevron-down ml-1 small"></span>
          </button>
          <div className="dropdown-menu dropdown-menu-right border-0 shadow-sm" aria-labelledby="logout-dropdown">
            <a className="dropdown-item" target='_blank' href="https://spyderads.firstpromoter.com/">Affiliate</a>
            <a className="dropdown-item" href="/plan-history">Plan History</a>
            <a className="dropdown-item" href="/edit-profile">Settings</a>
            <a className="dropdown-item" onClick={handleLogout} href="#">Logout</a>
          </div>
        </div>
      </div>
    </nav>
    </>
  )
}

export default UserDashboarderHeader