import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Learn1 from '../images/learn1.png';
import Learn2 from '../images/learn2.png';

import Footer from "../components/Footer";
import Header from "../components/Header";
 
function Learn() {
  return (
    <>
    <div className='main-nav'>
    <Header />
    <section className='banner-section pricing-banner'>
      <span className='circle1 small-circle'></span>
      <Container>
        <Row className='justify-content-center'>
            <Col md='8'>
                <h1 className='banner-main-title'>Learn</h1>
                <p className='banner-p'> </p>
            </Col>
        </Row>
      </Container>
    </section>
    </div>

    <section className='our-top-features pt-5 pb-0 learn-main-outer'>
       <div className='container mt-5'>
          <Row className='align-items-center'>
            <Col md='6'>
              <div className='features-left mr-3 learn-left'>
                <img className='f1-img' src={Learn1} alt="learn1" />
              </div>
            </Col>
            <Col md='6'>
              <div className='features-right text-left'>
                  <div className='feature-upper count-feature-upper'>
                  <span className='count-number'>1</span><span className='loginto-span'>Login to Spyderads</span>
                  </div>
                  <p>.</p>
                  <button className='login-btn1 login-spyderads'>Login to Spyderads</button>
              </div>
            </Col>
          </Row>
          <Row className='align-items-center mt-5 mb-5 center-row'>
            <Col md='6'>
              <div className='features-right text-left feature-r-l'>
                  <div className='feature-upper count-feature-upper'>
                  <span className='count-number'>2</span><span className='loginto-span'>Visit The Facebook Ad Library</span>
                  </div>
                  <p></p>
                  <button className='login-btn1 login-spyderads'>Visit Facebook Ad Library</button>
              </div>
            </Col>
            <Col md='6'>
              <div className='features-left mr-3 learn-left'>
                <img className='f1-img' src={Learn2} alt="learn2" />
              </div>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col md='6'>
              <div className='features-left mr-3 learn-left'>
                <img className='f1-img' src={Learn1} alt="learn1" />
              </div>
            </Col>
            <Col md='6'>
              <div className='features-right text-left'>
                  <div className='feature-upper count-feature-upper'>
                  <span className='count-number'>3</span><span className='loginto-span'>See Your Ads In the Dashboard</span>
                  </div>
                  <p>.</p>
                  <button className='login-btn1 login-spyderads'>Visit Your Ads</button>
              </div>
            </Col>
          </Row>
          <Row className='align-items-center mt-5 mb-5 center-row'>
            <Col md='6'>
              <div className='features-right text-left feature-r-l'>
                  <div className='feature-upper count-feature-upper'>
                  <span className='count-number'>4</span><span className='loginto-span'>Discover New Ads in Our Ad Library</span>
                  </div>
                  <p></p>
                  <button className='login-btn1 login-spyderads'>Visit Facebook Ad Library</button>
              </div>
            </Col>
            <Col md='6'>
              <div className='features-left mr-3 feature-l-r learn-left'>
                <img className='f1-img' src={Learn2} alt="learn2" />
              </div>
            </Col>
          </Row>
       </div>
    </section>
   
    <Footer />
    

    </>
  )
}

export default Learn