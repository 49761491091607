import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Spinner from 'react-bootstrap/Spinner';
import Email from '../images/email.svg';
import Lock from '../images/Lock.svg';
import Profile from '../images/Profile.svg';
import axios from '../api/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { decryptData, app_base_url } from "../config/Configuration";

function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [referralId, setReferralId] = useState(0);



  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const myParam = queryParams.get('ref');
    //console.log('myParam:', myParam);
    //console.log('decryptDataString',decryptDataString(myParam)); 
    if (myParam != null) {
      const originalString = myParam;
      const newString = originalString.slice(3);
      //alert(newString);
      setReferralId(newString);
    }

  }, []);
  const handleSignup = async (event) => {


    event.preventDefault();
    setLoading(true);

    if (!name || !email || !password) {

      toast.error("Please fill in all the required fields.");
      setLoading(false);
      return;
    }
    try {

      const response = await axios.post('/register', { name, email, password, referralId });
      if (response.data.status) {
        toast.success('Registration successful. Please verify your email.');
        navigate("/login");
      } else if (response.data.errorCode === 'USER_ALREADY_EXISTS') {
        toast.error(response.data.message); // Display error message in toast
      } else {
        toast.error(response.data.message || "Login failed. Please try again."); // Display error message in toast
      }
      setLoading(false);
    } catch (e) {
      toast.error(e); // Display error message in toast
      setLoading(false);
    }

  }
  return (
    <>
      <div className='login-outer'>
        <span className='circle'></span>
        <div className='login-inner'>
          <a className='back-home' href="/homepage"><i className="fa-solid fa-circle-arrow-left"></i> Back to Home</a>
          <h1 className='login-title'>Sign Up</h1>
          <p className='desc-text'>Enter your login details below</p>

          <form onSubmit={handleSignup}>
            <div className='login-input'>
              <input className='custom-input' name="name" onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder='User' />
              <img className='input-img' src={Profile} alt='profile' />
            </div>
            <div className='login-input'>
              <input className='custom-input' name="email" onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder='Email' />
              <img className='input-img' src={Email} alt='email' />
            </div>
            <div className='login-input'>
              <input className='custom-input' name="password" onChange={(e) => setPassword(e.target.value)} value={password} type="password" placeholder='Password' />
              <img className='input-img' src={Lock} alt='lock' />
            </div>
            <button className='login-btn1' type='submit'>
              {
                !loading ? "Sign Up" :
                  <Spinner animation="border" role="status" size='sm'>
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
              }
            </button>
            <div className='account-text'>Already have an account? <a href="/login">Login</a></div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Register