import React, { useState, useEffect } from 'react';
import {  useDispatch } from 'react-redux';

import Blogo from '../images/black-logo.png';
import Pro from '../images/pro.png';
import D1 from '../images/d1.svg';
import D2 from '../images/d2.svg';
import D3 from '../images/d3.svg';
import D4 from '../images/d4.svg';
import Logout from '../images/logout.svg';
import P1 from '../images/p1.png';
import P2 from '../images/p2.png';
import P3 from '../images/p3.png';
import P4 from '../images/p4.png';
import P1c from '../images/p1c.png';
import P2c from '../images/p2c.png';
import P3c from '../images/p3c.png';
import P4c from '../images/p4c.png';
import ProfileImg from '../images/profile-img.png';
import Camera from '../images/Camera.png';
import Lock from '../images/Lock.svg';
import axios from '../api/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import UserProfileSidebar from '../components/UserProfileSidebar';
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';

function ChangePassword() {
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const [email, setEmail] = useState(null);
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  useEffect(() => {
    const storedUserName = JSON.parse(localStorage.getItem('user'));
    if (storedUserName) {
      setEmail(storedUserName);
    }
  }, []);

  const handleChangePassword = async (event) => {
    event.preventDefault();

    if (!old_password || !new_password || !confirm_password) {
      toast.error('Please fill in all the required fields.');

    }
    else if (new_password != confirm_password) {
      toast.error("New and confirm password does not match.");

    }
    else {
      dispatch(startLoading());
      try {
        const response = await axios.post('/change-password', { email, old_password, new_password, confirm_password });

        if (response.data.status) {

          toast.success(response.data.message);

        } else {
          toast.error(response.data.message || "Login failed. Please try again.");
        }
        dispatch(stopLoading());
      } catch (e) {
        toast.error(e.message || "An error occurred. Please try again.");
        dispatch(stopLoading());
      }
    }
  }

  return (
    <>
      <DashboarderHeader />
      <div className="container-fluid">
        <div className="row">
          <DashboarderSidebar />
          <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
          <div className="col-md-9 col-lg-10 ml-md-auto px-0 custom-nine-col">
            <main className="container-fluid main-container">
              <div className='profile-div'>
                <UserProfileSidebar />
                <div className='login-inner'>
                  <h1 class="login-title">Change Password</h1>
                  <p class="desc-text">Create your new password here.</p>
                  <div className='profile-inner edit-profile-div change-password'>
                    <div className='profile-inputs'>
                      <form onSubmit={handleChangePassword}>
                        <div className='login-input'>
                          <input className='custom-input' onChange={(e) => setEmail(e.target.value)} value={email} type="hidden" />
                          <input className='custom-input' onChange={(e) => setOldPassword(e.target.value)} value={old_password} type="password" placeholder='Old Password' />
                          <img className='input-img' src={Lock} alt='lock' />
                        </div>
                        <div className='login-input'>
                          <input className='custom-input' type="password" onChange={(e) => setNewPassword(e.target.value)} value={new_password} placeholder='New Password' />
                          <img className='input-img' src={Lock} alt='lock' />
                        </div>
                        <div className='login-input'>
                          <input className='custom-input' type="password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirm_password} placeholder='Confirm Password' />
                          <img className='input-img' src={Lock} alt='lock' />
                        </div>
                        <button className='login-btn1' type='submit'>Change</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>


    </>
  )
}

export default ChangePassword