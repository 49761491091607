import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'swiper/css';
import 'swiper/css/pagination';
import Footer from "../components/Footer";
import Header from "../components/Header";

function Privacy() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    /*  useEffect(() => {
       fetch(`${process.env.REACT_APP_API_BASE_URL}/Privacy`)
         .then(response => {
           if (!response.ok) {
             throw new Error('Network response was not ok');
           }
           return response.json();
         })
         .then(responseData => {
           if (responseData.status) {
             setData(responseData.data);
           } else {
             throw new Error('Error fetching data');
           }
           setLoading(false);
         })
         .catch(error => {
           setError(error);
           setLoading(false);
         });
     }, []); */

    return (
        <>
            <div className='main-nav'>
                <Header />
                <section className='banner-section pricing-banner'>
                    <span className='circle1 small-circle'></span>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md='8'>
                                <h1 className='banner-main-title'>Privacy Policy</h1>
                                <p className='banner-p'></p>
                              
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>


            <section className='pricing-sec review-section pt-0 mt-5 privacy-main-outer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='main-title mb-5'>Privacy Policy</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-10 offset-md-1'>

                        <h3>  1. Introduction</h3>

<p>Welcome to SpyderAds ("we," "our," or "us"). We are committed to protecting your privacy. This Privacy Policy outlines our practices regarding the collection, use, and protection of your personal information.</p>

<h3>2. Information We Do Not Collect</h3>

<p>We want to assure you that we do not collect any personal information from our users. Personal information includes, but is not limited to, your name, email address, phone number, and any other data that could be used to identify you personally.</p>

<h3>3. No Sharing or Selling of Data</h3>

<p>We do not sell, trade, or otherwise transfer your personal data to third parties. Since we do not collect personal information, there is no data to be shared or sold.</p>

<h3>4. Security</h3>

<p>Although we do not collect personal data, we are committed to ensuring that any non-personal information or data you provide is stored securely. We use appropriate technical and organizational measures to protect our systems from unauthorized access.</p>

<h3>5. Changes to This Policy</h3>

<p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page with an updated effective date.</p>

<h3>6. Contact Us</h3>

<p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:</p>

{/* [Your Contact Information]
 */}
<p>Thank you for using SpyderAds.</p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />


        </>
    )
}

export default Privacy