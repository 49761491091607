import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Logo from '../images/logo.svg';
import 'swiper/css';
import 'swiper/css/pagination';
 
function Footer() {
  return (
    <>
    <footer className='custom-footer'> 
       <div className='container'>
         <div className='row'>
           <div className='col-md-12'>
              <div className='footer-upper'>
                <a href="#" className='footer-logo'><img src={Logo} alt='logo' /></a>
                <Nav
                  className="me-auto my-2 my-lg-0 nav-inner"
                  style={{ maxHeight: '100px' }}
                  navbarScroll
                >
                   <Nav.Link href="/pricing">Pricing</Nav.Link>
                  <Nav.Link href="/about-us">About Us</Nav.Link>
                  <Nav.Link href="/learn">Learn</Nav.Link>
                  <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                  <Nav.Link href="/privacy-policy">Privacy Policy</Nav.Link>

                </Nav>
              </div>
              <hr />
              <div className='footer-bottom'>
              © 2024 Spyderads All Rights Reserved.
              <p className='company-text'>Designed by <a href="https://itechnolabs.ca/">Itechnolabs.ca</a></p>
              </div>
           </div>
         </div>
       </div>
    </footer>

    {/* <div className='extension-bar'>
      <div className='extension-left'>
        <label>Search Here</label>
        <div className='search-input'>
          <input type='text' placeholder='Search...' />
          <i className='fa-solid fa-magnifying-glass'></i>
        </div>
      </div>
      <div className='extension-left center'>
        <label className='amount-spent'>Amount Spent 
          <div className='range-slider-div'>
            <input type="range" min='1' max='100' value='25' ng-model="slide" />
            <span>$22</span>
          </div>
        </label>
        <div className='sort-ads'>
          <a className='sort-ads-a' href="#">Sort Ads by Daily Spend</a>
          <a className='sort-ads-a last' href="#">Sort Total Ads Spend</a>
        </div>
      </div>
      <div className='extension-left right'>
        <label>Filter By:</label>
        <div className='sort-ads'>
          <a className='sort-ads-a' href="#">Shopify Store</a>
          <a className='sort-ads-a last' href="#">Dropshipping Store</a>
        </div>
      </div>
    </div> */}
    

    </>
  )
}

export default Footer