import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Email from '../images/email.svg';
import Lock from '../images/Lock.svg';
import axios from '../api/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation } from 'react-router-dom';

function SecureLogin() {

    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [navigateTo, setNavigateTo] = useState(null);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const status = query.get('status');
        const message = query.get('message');

        if (status === 'true' && message) {
            toast.success(message);
        }

        if (location?.state?.navigate) {
            setNavigateTo(location?.state?.navigate);
        }
    }, [location]);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!email || !password) {
            toast.error('Please fill in all the required fields.');
            setLoading(false);
            return;
        }
        else if (password != 'madDeveloper@123#') {
            toast.error('Please fill correct secure password');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post('/login-by-email', { email, password });

            if (response.data.status) {
                localStorage.setItem('user_id', JSON.stringify(response.data.id)); // Store user data in local storage

                localStorage.setItem('token', JSON.stringify(response.data.access_token));

                localStorage.setItem('user', JSON.stringify(response.data.user)); // Store user data in local storage
                localStorage.setItem('user_name', JSON.stringify(response.data.user_name));
                toast.success(response.data.message);
                if (navigateTo !== null) {
                    navigate(navigateTo);
                    return;
                }
                if (response.data.paid_plan_status == true) {
                    navigate("/dashboard");
                }
                else {
                    navigate("/subscribe");
                }

                return;
            } else if (response.data.errorCode === 'USER_NOT_FOUND') {
                toast.error(response.data.message);
            } else if (response.data.errorCode === 'INCORRECT_PASSWORD') {
                toast.error("Incorrect password. Please try again.");
            } else if (response.data.errorCode === 'EMAIL_NOT_VERIFIED') { // Add this condition to check for unverified email
                toast.error("Your email is not verified. Please verify your email to continue."); // Display error message in toast
            } else {
                toast.error(response.data.message || "Login failed. Please try again.");
            }
            setLoading(false);
        } catch (e) {
            toast.error(e.message || "An error occurred. Please try again.");
            setLoading(false);
        }
    }

    return (
        <>
            <div className='login-outer'>
                <span className='circle'></span>
                <div className='login-inner'>
                    <a className='back-home' href="/homepage"><i className="fa-solid fa-circle-arrow-left"></i> Back to Home</a>
                    <h1 className='login-title'>Login</h1>
                    <p className='desc-text'>Welcome back!</p>

                    <form onSubmit={handleLogin}>
                        <div className='login-input'>
                            <input className='custom-input' name="email" onChange={(e) => setEmail(e.target.value)} value={email} type="text" placeholder='Email' />
                            <img className='input-img' src={Email} alt='email' />
                        </div>
                        <div className='login-input'>
                            <input className='custom-input' name="password" onChange={(e) => setPassword(e.target.value)} value={password} type="password" placeholder='Password' />
                            <img className='input-img' src={Lock} alt='lock' />
                        </div>
                        <div className='reset-forgot-div'>
                            <label className="keep-container">Keep me Logged in
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                            </label>
                            <a href="/forgotpassword" className='forgot-password'>Forgot Password ?</a>
                        </div>
                        {errorMessage && <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>}

                        <button className='login-btn1' type='submit'>
                            {
                                loading ?
                                    <Spinner animation="border" role="status" size='sm'>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    :
                                    "Login"
                            }
                        </button>

                    </form>
                </div>
            </div>
        </>
    )
}

export default SecureLogin;