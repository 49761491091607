import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';


import Lock from '../images/Lock.svg';
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';
import axios from '../api/axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../config/Spinner';

function ResetPassword() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingStatus);

  const [password, setPassword] = useState("");
 

  const token = JSON.parse(localStorage.getItem('token'));

  const changePassword = () => {

    if (password === '') {
      toast.error('Please fill code.');

    }

    else {
      dispatch(startLoading());

      const config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      };

      const bodyParameters = {

        otp: password
      };

      axios
        //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
        .post("/password/code/check", bodyParameters, config)
        .then((response) => {
          //  console.log('res.result', res.data);
          if (response.data.status) {

            toast.success(response.data.message);


          }
          else {
            toast.success(response.data.message || " Code not match.");
          }
          dispatch(stopLoading());
        })
        .catch((err) => {
          dispatch(stopLoading());
        });

    }

  };
  return (
    <>
             {loading.loadingStatus? <Spinner /> : <div></div>}

      <div className='login-outer'>
        <span className='circle'></span>
        <div className='login-inner'>
          <a className='back-home' href="/login"><i className="fa-solid fa-circle-arrow-left"></i> Back to Login</a>
          <h1 className='login-title'>Reset Password</h1>
          <p className='desc-text'>Please enter OTP here.</p>

          <form>
            <div className='login-input'>
              <input className='custom-input' type="text" onChange={(e) => setPassword(e.target.value)} value={password} placeholder='Enter otp' />
              <img className='input-img' src={Lock} alt='lock' />
            </div>

            <button className='login-btn1' onClick={changePassword} type='button'>Reset Password</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ResetPassword