import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
function  Otp() {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();

  const handlCheckCode = async (event) => {
    event.preventDefault(); 
    
    if (!otp) {
      toast.error('Please fill in all the required fields.');
      return;
    }
    
    try {
      const response = await axios.post('/password/code/check', { otp });
      
      if (response.data.status) {

        toast.success(response.data.message);
        navigate("/login");
      } else {
        toast.error(response.data.message || "Login failed. Please try again.");
      }
    } catch(e) {
      toast.error(e.message || "An error occurred. Please try again.");
    }
  }

  return (
    <>
      <div className='login-outer'>
          <span className='circle'></span>
        <div className='login-inner'>
          <a className='back-home' href="/homepage"><i className="fa-solid fa-circle-arrow-left"></i> Back to Home</a>
          <h1 className='login-title'>Enter OTP</h1>
          <p className='desc-text'>Enter the OTP that we just sent you on
your email address to reset
your password.</p>
          <form onSubmit={handlCheckCode}>
            <div className='login-input otp-inputs'>
              
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <button className='login-btn1' type='submit'>Submit</button>
            </form>
        </div>
      </div>
    </>
  )
}

export default Otp