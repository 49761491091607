import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { dateFromMillisecond } from '../config/Configuration';
import Sponser from '../images/sponsered.png';
import Simg from '../images/no-image-256.png';
import blackCircle from '../images/blackCircle.jpg';
import Fb from '../images/fb.png';
import axios from '../api/axios';

import { ToastContainer, toast } from 'react-toastify';
import Amazon from '../images/amazon.png';
import Following1 from '../images/following1.png';
import Following2 from '../images/following2.png';
import Following3 from '../images/following3.png';
import Following4 from '../images/following4.png';
import Following5 from '../images/following5.png';
import Following6 from '../images/following6.png';
import Myntra from '../images/myntra.png';

function FollowerAdBoxCardItem(props) {

    const maxLength = 100;

    const token = JSON.parse(localStorage.getItem('token'));

    const [savedStatus, setSavedStatus] = useState(false);
    const [brandImage, setBrandImage] = useState(false);
    const [mediaImage, setMediaImage] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        loadDefauldMedia();

    }, []);
    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };


  

    const loadDefauldMedia = () => {
        let tempBrandImg = '';//record.snapshot ? record.snapshot.page_profile_picture_url : '';

        if (props.ad.ad_logo) {
            tempBrandImg = process.env.REACT_APP_BACKEND_BASE_URL+'/' + props.ad.ad_logo;

        }
        else if ( props.record.snapshot.page_profile_picture_url) {
            tempBrandImg =  props.record.snapshot.page_profile_picture_url;

        }
     
        setBrandImage(tempBrandImg);

        let tempMediaImg = '';

        if (props.ad.ad_image) {
            tempMediaImg = process.env.REACT_APP_BACKEND_BASE_URL+'/' + props.ad.ad_image;

        }
        else if (props.ad.cardResizedImageUrl) {
            tempMediaImg = props.ad.cardResizedImageUrl;

        }

        setMediaImage(tempMediaImg);
    };
    const onErrorBrandImage = () => {
        setBrandImage(blackCircle);
    };

    const onErrorMediaImage = () => {
        setMediaImage(Simg);
    };


    return (
        <>
            <div className='download-media'>
                <div className='bookmark-title'><span>Apr 10, 2024</span></div>
                {/*  <div className='time-span-div d-media-right'>
                                    <span className='views'>8.20M Views</span>
                                    <span className='views-price'>€20.5-€25</span>
                                </div> */}
                <img className='follow-img' src={mediaImage} alt='s-img' onError={() => onErrorMediaImage()} />
                <p>
                {(() => {
                    if (props.ad.cardBody) {
                        return (
                          
<>
                                {isExpanded ? props.ad.cardBody : `${props.ad.cardBody.substring(0, maxLength)}...`}
                                <button className='check-spend more' onClick={toggleReadMore}>
                                    {isExpanded ? 'Read Less' : 'Read More'}
                                </button>
                                </>
                        )
                    }
                })()}

                </p>
                <a target='_blank' href={props.record && props.record.adArchiveID ? 'https://www.facebook.com/ads/library/?id='+props.record.adArchiveID : ''} className='login-btn1 see-ads'>See Ad</a>
                <a target='_blank' href={props.record && props.record.snapshot ? props.record.snapshot.page_profile_uri : ''} className='download-media-addbtn'> See Profile</a>
            </div>
        </>
    )
}

export default FollowerAdBoxCardItem